import React from "react"
import ShowDetails from "../components/Index/Details/ShowDetails"

const DetailsPage = () => {
  return (
    <div>
      <ShowDetails />
    </div>
  )
}

export default DetailsPage
