export default function validateInfo(values) {
  let errors = {}

  if (!values.firstName.trim()) {
    errors.firstName = "FirstName required"
  }
  if (!values.lastName.trim()) {
    errors.lastName = "LastName required"
  }
  if (!values.phoneNumber.trim()) {
    errors.phoneNumber = "PhoneNumber required"
  }
  if (!values.email) {
    errors.email = "Email required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid"
  }
  if (!values.password) {
    errors.password = "Password is required"
  } else if (values.password.length < 6) {
    errors.password = "Password needs to be 6 characters or more"
  }
  return errors
}
