import React from "react"
import {
  ContactContainer,
  SubProjectContainer,
  BtnDownloadPDF,
} from "./showElements"

const ShowAdditional = (props) => {
  return (
    <ContactContainer>
      <SubProjectContainer>
        <BtnDownloadPDF onClick={props.handleDownloadPDF}>
          Download PDF
        </BtnDownloadPDF>
      </SubProjectContainer>
      <SubProjectContainer>
        <BtnDownloadPDF onClick={props.handleDownloadFile}>
          Download File
        </BtnDownloadPDF>
      </SubProjectContainer>
    </ContactContainer>
  )
}

export default ShowAdditional
