import React, { useState, useEffect, useCallback } from "react"
import { BtnEdit } from "./customerElement"
import "../Admin/Styles.css"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import authService from "../../api/axios"
import {
  LabelModel,
  ModelContainer,
  InputModel,
  RowModel,
  BtnCreate,
  RowAddress,
  ColAddress,
} from "./customerElement"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
}

const FormEdit = (props) => {
  const token = localStorage.getItem("token")
  const [open, setOpen] = React.useState(false)
  const [, setIsSubmitting] = useState(false)
  const [values, setValues] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    customerID: "",
  })
  const [address, setAddress] = useState({
    postcode: "",
    buildingName: "",
    houseNo: "",
    street: "",
    district: "",
    subDistrict: "",
    province: "",
    countryID: "",
    moo: "",
    village: "",
    alley: "",
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }
  const handleChangeAddress = (e) => {
    const { name, value } = e.target
    setAddress({
      ...address,
      [name]: value,
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    await authService.updateCustomer(token, props.id, values, address).then(
      () => {
        handleClose()
        window.location.reload()
      },
      (error) => {
        console.log(error)
      }
    )
  }
  const fetchData = useCallback(async () => {
    await authService.getCustomerInfo(token, props.id).then((response) => {
      let newData = response.data
      setValues({
        id: newData.id,
        firstName: newData.firstName,
        lastName: newData.lastName,
        email: newData.email,
        customerID: newData.customerID,
      })
      setAddress({
        postcode: newData.address.postcode,
        buildingName: newData.address.buildingName,
        houseNo: newData.address.houseNo,
        street: newData.address.street,
        district: newData.address.district,
        subDistrict: newData.address.subDistrict,
        province: newData.address.province,
        countryID: newData.address.countryID,
        moo: newData.address.moo,
        village: newData.address.village,
        alley: newData.address.alley,
      })
    })
  }, [props.id, token])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <>
      <BtnEdit onClick={handleOpen}>แก้ไข</BtnEdit>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Customer
          </Typography>
          <ModelContainer>
            <RowAddress>
              <ColAddress>
                <LabelModel>CustomerID</LabelModel>
                <InputModel
                  type="text"
                  name="firstName"
                  disabled
                  onChange={handleChange}
                  value={values.customerID}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>Email</LabelModel>
                <InputModel
                  disabled
                  autoComplete="off"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>First Name</LabelModel>
                <InputModel
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>Last Name</LabelModel>
                <InputModel
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>เลขที่บ้าน</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="houseNo"
                  onChange={handleChangeAddress}
                  value={address.houseNo}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>อาคาร ชั้น ห้อง</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="buildingName"
                  onChange={handleChangeAddress}
                  value={address.buildingName}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>ซอย</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="alley"
                  onChange={handleChangeAddress}
                  value={address.alley}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>ถนน</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="street"
                  onChange={handleChangeAddress}
                  value={address.street}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>หมู่บ้าน</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="village"
                  onChange={handleChangeAddress}
                  value={address.village}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>หมู่ที่</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="moo"
                  onChange={handleChangeAddress}
                  value={address.moo}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>แขวง/ตำบล</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="subDistrict"
                  onChange={handleChangeAddress}
                  value={address.subDistrict}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>เขต/อำเภอ</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="district"
                  onChange={handleChangeAddress}
                  value={address.district}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>จังหวัด</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="province"
                  onChange={handleChangeAddress}
                  value={address.province}
                />
              </ColAddress>
              <ColAddress>
                <LabelModel>เลขไปรษณีย์</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="postcode"
                  onChange={handleChangeAddress}
                  value={address.postcode}
                />
              </ColAddress>
            </RowAddress>
            <RowAddress>
              <ColAddress>
                <LabelModel>ประเทศ</LabelModel>
                <InputModel
                  autoComplete="off"
                  type="text"
                  name="countryID"
                  onChange={handleChangeAddress}
                  value={address.countryID}
                />
              </ColAddress>
              <RowModel>
                <BtnCreate onClick={handleClose}>Cancel</BtnCreate>
                <BtnCreate onClick={handleSubmit}>Save</BtnCreate>
              </RowModel>
            </RowAddress>
          </ModelContainer>
        </Box>
      </Modal>
    </>
  )
}

export default FormEdit
