import React from "react"
import Submit from "../components/Submit/Submit"

const SubmitPage = () => {
  return (
    <>
      <Submit />
    </>
  )
}

export default SubmitPage
