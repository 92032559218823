import { Modal, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment/moment"
import React, { useState, useEffect } from "react"
import { BtnIndex,  InputModel, LabelModel, ModelContainer, RowContainer, RowModel } from "./indexElements"
import "./Styles.css"
import authService from "../../api/axios"
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 'auto',
    bgcolor: "background.paper",
    backgroundColor: '#fff',
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
  }

const FormDownloadSVG = () => {
  const token = localStorage.getItem("token")
    const [open, setOpen] = React.useState(false) 
    const [values, setValues] = useState({
        startDate: moment( moment().subtract(1, 'day').toDate()).format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      })

      const handleOpen = () => setOpen(true)
      const handleClose = () => setOpen(false)
    
      const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
          ...values,
          [name]: value,
        })
      }

      const handleSubmit= async(e)=>{
        if(!values.startDate || !values.endDate){
          return  alert('Please! check start date or end date is empty. ');
        }
        e.preventDefault()
        try {
          const response = await authService.getDownloadCSV(token,moment(values.startDate).format('YYYY-M-DD'),moment(values.endDate).format('YYYY-M-DD')).then(  
            () => {
              setOpen(false);
            })
          console.log(response)
        } catch (err) {
          alert(err)
          console.log(err)
        }
      }


    return <>  
   <Box sx={{display:'flex' ,flexDirection:'row',justifyContent:'flex-end' ,p:2}}>
    <BtnIndex variant="contained" onClick={handleOpen} >DOWNLOAD CSV</BtnIndex>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          DOWNLOAD CSV
          </Typography>
          <ModelContainer>
            <LabelModel>Start Date</LabelModel>
            <InputModel
              type="date"
              name="startDate"
              onChange={handleChange}
              value={values.startDate}
            />
            <LabelModel>endDate</LabelModel>
            <InputModel
              type="date"
              name="endDate"
              onChange={handleChange}
              value={values.endDate}
            />
            
            <RowModel>
              <BtnIndex onClick={handleClose}>Cancel</BtnIndex>
              <BtnIndex onClick={handleSubmit}>Download</BtnIndex>
            </RowModel>
          </ModelContainer>
        </Box>
      </Modal>
    </Box>
    
    </>
}

export default FormDownloadSVG
