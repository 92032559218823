import styled from "styled-components"
import React from "react"
import { Link as LinkR } from "react-router-dom"
import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import * as RiIcons from "react-icons/ri"

export const NavIcon = styled.div`
  margin-left: 2rem;
  font-size: 2rem;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const SidebarNav = styled.nav`
  background: #fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
`

export const SidebarWrap = styled.div`
  width: 100%;
`
export const SidebarLink = styled(LinkR)`
  display: flex;
  color: #ffd100;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  &.active {
    border-left: 5px solid #ffd100;
  }
  &:hover {
    border-left: 5px solid #ffd100;
    cursor: pointer;
  }
`

export const SidebarLabel = styled.span`
  margin-left: 16px;
`

export const DropdownLink = styled(LinkR)`
  background: #fff;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #998c97;
  font-size: 16px;
  &:hover {
    color: #ffd100;
    cursor: pointer;
  }
`
export const SidebarCustom = [
  {
    title: "Overview",
    path: "/overview",
    icon: <AiIcons.AiOutlineHome color="#ffd100" />,
    iconClosed: <RiIcons.RiArrowDownSFill color="#ffd100" />,
    iconOpened: <RiIcons.RiArrowUpSFill color="#ffd100" />,

    subNav: [
      {
        title: "ยื่นคำขอใหม่",
        path: "/overview/submit",
      },
      {
        title: "เพิกถอน",
        path: "/overview/revoke",
      },
    ],
  },
]
export const SidebarHQ = [
  {
    title: "Overview",
    path: "/overview",
    icon: <AiIcons.AiOutlineHome color="#ffd100" />,
    iconClosed: <RiIcons.RiArrowDownSFill color="#ffd100" />,
    iconOpened: <RiIcons.RiArrowUpSFill color="#ffd100" />,

    subNav: [
      {
        title: "ยื่นคำขอใหม่",
        path: "/overview/submit",
      },
      {
        title: "เพิกถอน",
        path: "/overview/revoke",
      },
    ],
  },
  {
    title: "Admin",
    path: "/admin",
    icon: <RiIcons.RiCustomerService2Line color="#ffd100" />,
  },
  {
    title: "Customer",
    path: "/customer",
    icon: <FaIcons.FaRegUserCircle color="#ffd100" />,
  },
]
