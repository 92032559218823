import React, { useState, useEffect, useCallback } from "react"
import authService from "../../../api/axios"
import { useParams } from "react-router-dom"

const useForm = () => {
  const token = localStorage.getItem("token")
  const { id } = useParams()
  const [role, setRole] = React.useState("")
  const [isLoading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleCloseAround = async () => {
    setOpen(false)
  }
  const handleChangeRole = (event) => {
    setRole(event.target.value)
  }
  const [isCertificateType, setCertificateType] = React.useState("")
  const handleCertificateType = (event) => {
    setCertificateType(event.target.value)
  }
  const [createType, setType] = React.useState("")
  const [isStatus, setStatus] = React.useState("")
  const handleChangeType = (event) => {
    setType(event.target.value)
  }
  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }
  const [docs1, setDocuments1] = useState({
    jobNumber: "",
    certificateType: "",
    certificateNo: "",
    fullNameTH: "",
    fullNameEN: "",
    createType: "",
    objective: "",
    juristicPersonOrOrganizationTH: "",
    juristicPersonOrOrganizationEN: "",
    taxNo: "",
    businessType: "",
    registrationDate: "",
    cardId: "",
    startDate: "",
    endDate: "",
    passport: "",
    country: "ไทย",
    passportExpire: "",
    occupation: "",
    birthday: "",
    officeLocation: "HQ",
    status: "",
  })
  const [headOffice, setHeadOffice] = useState(false)
  const [branch, setBranch] = useState(false)
  const [docsAddress, setDocsAddress] = useState({
    headOffice: false,
    branch: false,
    branchNo: "",
    houseNo: "",
    moo: "",
    building: "",
    soi: "",
    street: "",
    subDistrict: "",
    district: "",
    province: "",
    postalCode: "",
    nameCoordinator: "",
    phoneNumber: "",
    email: "",
  })
  const [subdistrict, setSubDistrict] = useState("")
  const [district, setDistrict] = useState("")
  const [province, setProvince] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [isOtherAddress, setOtherAddress] = useState({
    isCheck: false,
    postCode: "",
    building: "",
    street: "",
    moo: "",
    alley: "",
    subDistrict: "",
    district: "",
    province: "",
    phoneNumber: "",
    fax: "",
    houseNo: "",
  })
  const [sendToEmail, setSendToEmail] = useState(false)
  const [sendToAddress, setSendToAddress] = useState(false)
  const [sendOtherAddress, setSendOtherAddress] = useState(false)
  const [personalCertificate, setPersonalCertificate] = useState({
    email: "",
    pyear: 0,
  })
  const [webServerCertificate, setWebServerCertificate] = useState({
    commonName: "",
    organizationUnit: "",
    organization: "",
    locality: "",
    country: "",
    wyear: 0,
  })
  const [Project1, setProject1] = useState(false)
  const [Project2, setProject2] = useState(false)
  const [Project3, setProject3] = useState(false)
  const [Project4, setProject4] = useState(false)
  const [Project5, setProject5] = useState(false)
  const [Project6, setProject6] = useState(false)
  const [Project7, setProject7] = useState(false)
  const [p3Details, setP3Details] = useState("")
  const [p4Details, setP4Details] = useState({
    name: "",
    idCard: "",
    expireDate: "",
  })
  const [p6Details, setP6Details] = useState("")
  const [p7Details1, setP7Details1] = useState(false)
  const [p7Details2, setP7Details2] = useState(false)
  const [p7Details3, setP7Details3] = useState(false)
  const [p7Details4, setP7Details4] = useState(false)
  const [p7juristicPerson, setp7juristicPerson] = useState({
    province: "",
    juristicName: "",
    juristicUID: "",
  })
  const [p7individualAndJuristic, setp7individualAndJuristic] = useState({
    functionaryEmail: "",
    functionaryName: "",
    subdivision: "",
    juristicName: "",
    juristicUID: "",
    idCard: "",
    province: "",
  })
  const [p7juristicAndRank, setp7juristicAndRank] = useState({
    name: "",
    subdivision: "",
    juristicName: "",
    juristicUID: "",
    province: "",
  })

  const fetchData = useCallback(async () => {
    await authService.getDocumentId(token, id).then((res) => {
      let newData = res.data
      setDocuments1({
        jobNumber: newData.jobNumber,
        certificateType: newData.certificateType,
        certificateNo: newData.certificateNo,
        fullNameTH: newData.fullNameTH,
        fullNameEN: newData.fullNameEN,
        createType: newData.createType,
        objective: newData.objective,
        juristicPersonOrOrganizationTH: newData.juristicPersonOrOrganizationTH,
        juristicPersonOrOrganizationEN: newData.juristicPersonOrOrganizationEN,
        taxNo: newData.taxNo,
        businessType: newData.businessType,
        registrationDate: newData.registrationDate,
        cardId: newData.cardId,
        startDate: newData.startDate,
        endDate: newData.endDate,
        passport: newData.passport,
        country: newData.country,
        passportExpire: newData.passportExpire,
        occupation: newData.occupation,
        birthday: newData.birthday,
        officeLocation: newData.officeLocation,
        status: newData.status,
      })
      setCertificateType(newData.certificateType)
      setRole(newData.officeLocation)
      setType(newData.createType)
      setStatus(newData.status)
      setDocsAddress({
        headOffice: newData.addressFromCertificate.headOffice,
        branch: newData.addressFromCertificate.branch,
        branchNo: newData.addressFromCertificate.branchNo,
        houseNo: newData.addressFromCertificate.houseNo,
        moo: newData.addressFromCertificate.moo,
        building: newData.addressFromCertificate.building,
        soi: newData.addressFromCertificate.soi,
        street: newData.addressFromCertificate.street,
        subDistrict: newData.addressFromCertificate.subDistrict,
        district: newData.addressFromCertificate.district,
        province: newData.addressFromCertificate.province,
        postalCode: newData.addressFromCertificate.postalCode,
        nameCoordinator: newData.addressFromCertificate.nameCoordinator,
        phoneNumber: newData.addressFromCertificate.phoneNumber,
        email: newData.addressFromCertificate.email,
      })
      setSubDistrict(newData.addressFromCertificate.subDistrict)
      setDistrict(newData.addressFromCertificate.district)
      setHeadOffice(newData.addressFromCertificate.headOffice)
      setProvince(newData.addressFromCertificate.province)
      setZipcode(newData.addressFromCertificate.postalCode)
      setBranch(newData.addressFromCertificate.branch)
      setOtherAddress({
        isCheck: newData.informingCharge.otherMailingAddress.isCheck,
        postCode: newData.informingCharge.otherMailingAddress.postCode,
        street: newData.informingCharge.otherMailingAddress.street,
        building: newData.informingCharge.otherMailingAddress.building,
        moo: newData.informingCharge.otherMailingAddress.moo,
        alley: newData.informingCharge.otherMailingAddress.alley,
        subDistrict: newData.informingCharge.otherMailingAddress.subDistrict,
        district: newData.informingCharge.otherMailingAddress.district,
        province: newData.informingCharge.otherMailingAddress.province,
        phoneNumber: newData.informingCharge.otherMailingAddress.phoneNumber,
        fax: newData.informingCharge.otherMailingAddress.fax,
        houseNo: newData.informingCharge.otherMailingAddress.houseNo,
      })
      setSendToEmail(newData.informingCharge.sendToEmail)
      setSendToAddress(newData.informingCharge.sendToAddress)
      setSendOtherAddress(newData.informingCharge.otherMailingAddress.isCheck)
      setPersonalCertificate({
        email: newData.serviceDescription.personalCertificate.email,
        pyear: newData.serviceDescription.personalCertificate.year,
      })
      setWebServerCertificate({
        commonName: newData.serviceDescription.webServerCertificate.commonName,
        organizationUnit:
          newData.serviceDescription.webServerCertificate.organizationUnit,
        organization:
          newData.serviceDescription.webServerCertificate.organization,
        locality: newData.serviceDescription.webServerCertificate.locality,
        country: newData.serviceDescription.webServerCertificate.country,
        wyear: newData.serviceDescription.webServerCertificate.year,
      })
      setProject1(newData.project1.isCheck)
      setProject2(newData.project2.isCheck)
      setProject3(newData.project3.isCheck)
      setProject4(newData.project4.isCheck)
      setProject5(newData.project5.isCheck)
      setProject6(newData.project6.isCheck)
      setProject7(newData.project7.isCheck)
      setP3Details(newData.project3.name)
      setP4Details({
        name: newData.project4.name,
        idCard: newData.project4.idCard,
        expireDate: newData.project4.expireDate,
      })
      setP6Details(newData.project6.name)
      setP7Details1(newData.project7.forIndividual.check)
      setP7Details2(newData.project7.juristicPerson.check)
      setP7Details3(newData.project7.individualAndJuristic.check)
      setP7Details4(newData.project7.juristicAndRank.check)
      setp7juristicPerson({
        province: newData.project7.juristicPerson.province,
        juristicName: newData.project7.juristicPerson.juristicName,
        juristicUID: newData.project7.juristicPerson.juristicUID,
      })
      setp7individualAndJuristic({
        functionaryEmail:
          newData.project7.individualAndJuristic.functionaryEmail,
        functionaryName: newData.project7.individualAndJuristic.functionaryName,
        subdivision: newData.project7.individualAndJuristic.subdivision,
        juristicName: newData.project7.individualAndJuristic.juristicName,
        juristicUID: newData.project7.individualAndJuristic.juristicUID,
        idCard: newData.project7.individualAndJuristic.idCard,
        province: newData.project7.individualAndJuristic.province,
      })
      setp7juristicAndRank({
        name: newData.project7.juristicAndRank.name,
        subdivision: newData.project7.juristicAndRank.subdivision,
        juristicName: newData.project7.juristicAndRank.juristicName,
        juristicUID: newData.project7.juristicAndRank.juristicUID,
        province: newData.project7.juristicAndRank.province,
      })
    })
  }, [id, token])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const PutupdateDocs = async (e) => {
    e.preventDefault()
    try {
      const response = await authService
        .updateDocs(
          id,
          token,
          docs1,
          docsAddress,
          isOtherAddress,
          sendToEmail,
          sendToAddress,
          personalCertificate,
          webServerCertificate,
          Project1,
          Project2,
          Project3,
          Project4,
          Project5,
          Project6,
          Project7,
          p3Details,
          p4Details,
          p6Details,
          p7Details1,
          p7Details2,
          p7Details3,
          p7Details4,
          p7juristicPerson,
          p7individualAndJuristic,
          p7juristicAndRank
        )
        .then(
          () => {
            alert("update success!")
            window.location.reload()
          },
          (error) => {
            console.log(error)
            alert(error)
          }
        )
      console.log(response)
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  const handleChangeDocs1 = (e) => {
    const { name, value } = e.target
    setDocuments1({
      ...docs1,
      [name]: value,
    })
  }
  const handleChangeAddress = (e) => {
    const { name, value } = e.target
    setDocsAddress({
      ...docsAddress,
      [name]: value,
    })
  }
  const handleChangeOtherAddress = (e) => {
    const { name, value } = e.target
    setOtherAddress({
      ...isOtherAddress,
      [name]: value,
    })
  }
  const handleWsc = (e) => {
    const { name, value } = e.target
    setWebServerCertificate({
      ...webServerCertificate,
      [name]: value,
    })
  }
  const handlePsc = (e) => {
    const { name, value } = e.target
    setPersonalCertificate({
      ...personalCertificate,
      [name]: value,
    })
  }
  const handleP4Details = (e) => {
    const { name, value } = e.target
    setP4Details({
      ...p4Details,
      [name]: value,
    })
  }
  const handleP7individualAndJuristic = (e) => {
    const { name, value } = e.target
    setp7individualAndJuristic({
      ...p7individualAndJuristic,
      [name]: value,
    })
  }
  const handleP7juristicPerson = (e) => {
    const { name, value } = e.target
    setp7juristicPerson({
      ...p7juristicPerson,
      [name]: value,
    })
  }
  const handleP7juristicAndRank = (e) => {
    const { name, value } = e.target
    setp7juristicAndRank({
      ...p7juristicAndRank,
      [name]: value,
    })
  }
  const handleDownloadPDF = async () => {
    setLoading(true)
    handleOpen()
    await authService.getDownloadPDF(token, id).then(
      () => {
        setLoading(false)
      },
      (error) => {
        setOpen(false)
        alert("เกิดข้อผิดพลาด" + error)
      }
    )
  }
  const handleDownloadFile = async () => {
    setLoading(true)
    handleOpen()
    await authService.getDownloadFile(token, id).then(
      () => {
        setLoading(false)
      },
      (error) => {
        setOpen(false)
        alert("เกิดข้อผิดพลาด" + error)
      }
    )
  }
  return {
    isLoading,
    open,
    docs1,
    role,
    createType,
    docsAddress,
    isOtherAddress,
    personalCertificate,
    webServerCertificate,
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6,
    Project7,
    p3Details,
    p4Details,
    p6Details,
    p7juristicPerson,
    p7individualAndJuristic,
    p7juristicAndRank,
    sendToEmail,
    sendToAddress,
    headOffice,
    branch,
    isStatus,
    p7Details1,
    p7Details2,
    p7Details3,
    p7Details4,
    isCertificateType,
    subdistrict,
    district,
    province,
    zipcode,
    sendOtherAddress,
    setSendOtherAddress,
    handleCloseAround,
    setRole,
    setZipcode,
    setProvince,
    setDistrict,
    setSubDistrict,
    handleDownloadPDF,
    handleDownloadFile,
    handleCertificateType,
    PutupdateDocs,
    handleChangeStatus,
    setHeadOffice,
    setBranch,
    setSendToAddress,
    setSendToEmail,
    handleChangeRole,
    handleChangeType,
    handleP7individualAndJuristic,
    handleP7juristicPerson,
    handleP7juristicAndRank,
    setP7Details1,
    setP7Details2,
    setP7Details3,
    setP7Details4,
    setProject1,
    setProject2,
    setProject3,
    setProject4,
    setProject5,
    setProject6,
    setProject7,
    handleP4Details,
    setP6Details,
    setP3Details,
    handlePsc,
    handleWsc,
    handleChangeDocs1,
    handleChangeAddress,
    handleChangeOtherAddress,
  }
}

export default useForm
