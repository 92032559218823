import React from "react"
import Admin from "../components/Admin/Admin"

const AdminPage = () => {
  return (
    <>
      <Admin />
    </>
  )
}

export default AdminPage
