import React from "react"
import SignIn from "../components/Login/Login"

const LoginPage = () => {
  return (
    <>
      <SignIn />
    </>
  )
}

export default LoginPage
