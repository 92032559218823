import React, { useState } from "react"
import authService from "../../api/axios"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  Input,
  ButtonContainer,
  BtnLinkSubmit,
  LinkLogin,
  GoLogin,
  RowContainer,
  BeforeFooter,
  HeadText,
  ModelTextTitle,
  ModelText,
} from "./forgotpasswordElements"
import Box from "@mui/material/Box"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"

const ForgotPassword = () => {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = useState("")
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    window.location.reload()
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault()
    try {
      const response = await authService.forgotPassword(email).then(
        (res) => {
          handleOpen()
        },
        (error) => {
          alert(error.response.data.error)
          console.log(error.response.data.error)
        }
      )
      console.log(response)
    } catch (err) {
      alert(err)
      console.log(err)
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{ fontSize: "30px", color: "gray" }}
              onClick={handleClose}
            />
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            <CheckCircleOutlineIcon
              sx={{ fontSize: "60px", color: "#ffd100" }}
            />
            <ModelTextTitle>ส่งคำขอสำเร็จ</ModelTextTitle>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
          >
            <ModelText>
              ทางระบบได้ทำการจัดส่งรหัสผ่านใหม่ไปยังอีเมลที่ท่านระบุไว้
              <br />
              โปรดตรวจสอบกล่องข้อความของท่าน
            </ModelText>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
          >
            <BtnLinkSubmit onClick={handleClose}>ตกลง</BtnLinkSubmit>
          </Typography>
        </Box>
      </Modal>
      <HeroContainer>
        <HeadText>NT ADMIN</HeadText>
        <MainContainer>
          <WelcomeText>Forgot password ?</WelcomeText>
          <InputContainer>
            <Input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              placeholder="Email address"
            />
          </InputContainer>
          <ButtonContainer>
            <BtnLinkSubmit onClick={handleForgotPassword}>Submit</BtnLinkSubmit>
          </ButtonContainer>
          <RowContainer>
            <GoLogin>Go to login?</GoLogin>
            <LinkLogin to="/">Login</LinkLogin>
          </RowContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </div>
  )
}

export default ForgotPassword
