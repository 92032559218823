import React from "react"
import Revoke from "../components/Revoke/Revoke"

const RevokePage = () => {
  return (
    <>
      <Revoke />
    </>
  )
}

export default RevokePage
