import React, { useEffect, useState } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import jwt_decode from "jwt-decode"
import LogoutIcon from "@mui/icons-material/Logout"
import {
  Nav,
  NavbarContainer,
  NavRow,
  NavbarLogo,
  NavBtn,
  NavSpac,
  DropdrowBtn,
} from "./navbarElements"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import "./Styles.css"

const Navbar = () => {
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState(undefined)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [data, setDatas] = useState([])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      const token = localStorage.getItem("token")
      setCurrentUser(user)
      var decoded = jwt_decode(token)
      let adminId = decoded.id
      const fetchData = async () => {
        await authService.getAdminInfo(token, adminId).then((response) => {
          setDatas(response.data)
        })
      }
      fetchData()
    }
  }, [])
  const logOut = () => {
    authService.logout()
    navigate("/")
    window.location.reload()
  }

  return (
    <div>
      <Nav>
        <NavbarContainer>
          <NavRow>
            <NavbarLogo to="/overview">NT</NavbarLogo>
          </NavRow>
          <NavBtn>
            <NavSpac>
              {currentUser ? (
                <DropdrowBtn
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {data.firstName}&nbsp;&nbsp;{data.lastName}
                </DropdrowBtn>
              ) : (
                <DropdrowBtn
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  @Admin
                </DropdrowBtn>
              )}
            </NavSpac>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={logOut}
                disableRipple
                sx={{
                  color: "#696969",
                  fontWeight: 400,
                  fontFamily: "Kanit",
                }}
              >
                <LogoutIcon />
                Logout
              </MenuItem>
            </Menu>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </div>
  )
}

export default Navbar
