import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../../api/axios"
import jwt_decode from "jwt-decode"
import {
  NavIcon,
  SidebarNav,
  SidebarWrap,
  SidebarHQ,
  SidebarCustom,
} from "../Sidebar/sidebarElements"
import SubMenu from "../Sidebar/SubMenu"
import { IconContext } from "react-icons/lib"

const Sidebar = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const [userRole, setRole] = useState("")
  const [, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
      var decoded = jwt_decode(token)
      setRole(decoded.officeLocation)
    } else {
      navigate("/")
    }
  }, [navigate, token])

  return (
    <>
      {}
      <IconContext.Provider value={{ color: "#fff" }}>
        <SidebarNav>
          {userRole === "HQ" ? (
            <SidebarWrap>
              <NavIcon></NavIcon>
              {SidebarHQ.map((item, index) => {
                return <SubMenu item={item} key={index} />
              })}
            </SidebarWrap>
          ) : (
            <SidebarWrap>
              <NavIcon to="#"></NavIcon>
              {SidebarCustom.map((item, index) => {
                return <SubMenu item={item} key={index} />
              })}
            </SidebarWrap>
          )}
        </SidebarNav>
      </IconContext.Provider>
    </>
  )
}

export default Sidebar
