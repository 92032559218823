import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  margin-top: 80px;
  margin-left: 250px;
  min-height: 100vh;
  flex-direction: column;
`
export const WelcomeText = styled.h2`
  margin-top: 2rem;
  margin-left: 2rem;
  font-weight: bold;
  color: #ffd100;
`
export const RowContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 1rem;
  justify-content: space-between;
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
`
export const RowContainerInput = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const CardContent = styled.div`
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  background: #ffe881;
  border-radius: 8px;
  padding: 1rem;
  color: #000;
  width: 250px;
  height: 160px;
  @media only screen and (max-width: 1240px) {
    margin-top: 1rem;
    width: 40vw;
    height: 20vh;
  }
`
export const Cardcolumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`
export const CardcolumnSecond = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.7rem;
`
export const HeadText = styled.div`
  background: #fff;
  border-radius: 30px;
  color: #545859;
  width: 80%;
  height: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #363a3d;
  text-align: center;
`
export const TitleText = styled.div`
  margin-top: 1rem;
  color: #545859;
  width: 180px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`
export const TableCard = styled.div`
  width: 100%;
  min-height: 40vh;
  background: #fff;
  border-radius: 8px;
  color: #000;
  margin-bottom: 4rem;
  padding: 2rem;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
`
export const StatusText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #00bc20;
`
export const TableText = styled.h2`
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #545859;
`
export const LinkDetail = styled(LinkR)`
  font-weight: 300;
`
export const StatusText1 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #008fbc;
`
export const StatusText2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #00bc20;
`
export const StatusText3 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #db0d00;
`
export const StatusText4 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ffd100;
`
export const StatusText5 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #001ebc;
`
export const StatusText6 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ff7a00;
`
export const StatusText7 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #c000df;
`
export const StatusText8 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #757473;
`
export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  min-width: 30%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #aaaaaa;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`
export const BtnIndex = styled.button`
  margin-left: 1rem;
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  min-width: 100px;
  height: 40px;
  padding: 8px 8px;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const ModelContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const InputModel = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const LabelModel = styled.label`
  color: #3c354e;
  font-size: 15px;
`
export const RowModel = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
export const ErrorsText = styled.p`
  color: red;
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  `
