import React, { useState, useEffect } from "react"
import authService from "../../api/axios"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  Input,
  ButtonContainer,
  BtnLinkLogin,
  TextWarning,
  ForgotPassword,
  RowContainer,
  BeforeFooter,
  HeadText,
  //   HeadLogo,
} from "./loginElements"

const SignIn = () => {
  const [, setCurrentUser] = useState(undefined)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const response = await authService.login(email, password).then(
        () => {
          navigate("/overview")
          window.location.reload()
        },
        (error) => {
          alert(error.response.data.error)
          console.log(error)
        }
      )
      console.log(response)
    } catch (err) {
      alert(err)
      console.log(err)
    }
  }
  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
      navigate("/overview")
    } else {
      authService.logout()
      navigate("/")
    }
  }, [navigate])
  return (
    <div>
      <HeroContainer>
        {/* <HeadLogo>NT Logo</HeadLogo> */}
        <HeadText>NT ADMIN</HeadText>
        <MainContainer>
          <WelcomeText>Login</WelcomeText>
          <InputContainer>
            <Input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              placeholder="Email"
            />
            <Input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              placeholder="Password"
            />
          </InputContainer>
          <ButtonContainer>
            <BtnLinkLogin onClick={handleLogin}>Login</BtnLinkLogin>
          </ButtonContainer>
          <ForgotPassword to="/forgotpassword">
            Forgot Password ?
          </ForgotPassword>
          <RowContainer>
            <TextWarning>
              ***ระบบนี้สงวนไว้ใช้สำหรับพนักงานของ NT เท่านั้น
              <br />
              หากพยายามเข้าระบบโดยไม่ได้รับอนุญาติ จะโดนดำเนินคดีอย่างถึงที่สุด
            </TextWarning>
          </RowContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </div>
  )
}

export default SignIn
