import React from "react"
import Customer from "../components/Customer/Customer"

const CustomerPage = () => {
  return (
    <>
      <Customer />
    </>
  )
}

export default CustomerPage
