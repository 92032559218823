import "./App.css"
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom"
import Index from "./pages/index"
import Login from "./pages/login"
import ForgotPassword from "./pages/forgotpassword"
import Submit from "./pages/submit"
import Revoke from "./pages/revoke"
import Admin from "./pages/admin"
import Customer from "./pages/customer"
import DetailsPage from "./pages/details"
import Sidebar from "./components/Sidebar/Sidebar"
import Navbar from "./components/์Navbar/Navbar"
import authService from "./api/axios"

import React, { useState, useEffect } from "react"

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = authService.getCurrentUser()
    if (user) {
      setCurrentUser(user)
    }
  }, [])
  return (
    <Routers>
      {currentUser ? (
        <>
          <Sidebar />
          <Navbar />
        </>
      ) : null}
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/overview" exact element={<Index />} />
        <Route path="/forgotpassword" exact element={<ForgotPassword />} />
        <Route path="/overview/submit" exact element={<Submit />} />
        <Route path="/overview/revoke" exact element={<Revoke />} />
        <Route path="/admin" exact element={<Admin />} />
        <Route path="/customer" exact element={<Customer />} />
        <Route path="/details/:id" exact element={<DetailsPage />} />
      </Routes>
    </Routers>
  )
}

export default App
