import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../../../api/axios"
import useForm from "./useForm"
import ShowCertificate from "./ShowCertificate"
import ShowRevoke from "./ShowRevoke"
import ShowContact from "./ShowContact"
import ShowService from "./ShowService"
import ShowAdditional from "./ShowAdditional"
import Box from "@mui/material/Box"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import CircularProgress from "@mui/material/CircularProgress"
import CloseIcon from "@mui/icons-material/Close"

import {
  HeroContainer,
  MainContainer,
  WelcomeText,
  InputContainer,
  BeforeFooter,
  InputHeader,
  LabelSecond,
  ButtonContainer,
  BtnNext,
  LabelHeader,
  BtnLinkLogin,
  SelectProfile,
  GoBackHeader,
  StatusContainer,
  BtnPrev,
  ModelTextTitle,
} from "./showElements"

const step1 = "1. ชื่อและหลักฐานผู้ขอใช้บริการ (Customer Profile)"
const step2 =
  "2. ที่อยู่ในการติดต่อเพื่อส่งใบแจ้งค่าใช้บริการ และเอกสารอื่นๆ\n(Contact Address for sending billing/informing charge or other documents)"
const step3 = "3. รายละเอียดบริการ (Service Description)"
const step4 = "เอกสารประกอบ (Additional Documents)"
const status = [
  "ยื่นคำขอ",
  "อนุมัติแล้ว",
  "ถูกปฏิเสธ",
  "อยู่ระหว่างตรวจสอบ",
  "ส่งต้นฉบับ",
  "รอเอกสารตอบกลับ",
  "ออกใบรับรอง",
  "ยกเลิกคำขอ",
]
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
}
const ShowDetails = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [, setCurrentUser] = useState(undefined)
  const FormTitle = [step1, step2, step3, step4]
  const {
    isLoading,
    open,
    docs1,
    role,
    createType,
    docsAddress,
    isOtherAddress,
    sendTo,
    personalCertificate,
    webServerCertificate,
    Project,
    p3Details,
    p4Details,
    p6Details,
    p7juristicPerson,
    p7individualAndJuristic,
    p7juristicAndRank,
    sendToEmail,
    sendToAddress,
    headOffice,
    branch,
    isStatus,
    p7Details1,
    p7Details2,
    p7Details3,
    p7Details4,
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6,
    Project7,
    isCertificateType,
    subdistrict,
    district,
    province,
    zipcode,
    sendOtherAddress,
    setSendOtherAddress,
    handleCloseAround,
    setRole,
    setZipcode,
    setProvince,
    setDistrict,
    setSubDistrict,
    handleCertificateType,
    setProject1,
    setProject2,
    setProject3,
    setProject4,
    setProject5,
    setProject6,
    setProject7,
    PutupdateDocs,
    handleChangeStatus,
    setHeadOffice,
    setBranch,
    setSendToAddress,
    setSendToEmail,
    handleChangeRole,
    handleChangeType,
    handleP7individualAndJuristic,
    handleP7juristicPerson,
    handleP7juristicAndRank,
    handleP4Details,
    setP6Details,
    setP3Details,
    handlePsc,
    handleWsc,
    handleChangeDocs1,
    handleChangeAddress,
    handleChangeOtherAddress,
    setP7Details1,
    setP7Details2,
    setP7Details3,
    setP7Details4,
    handleDownloadPDF,
    handleDownloadFile,
  } = useForm()

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
      window.location.reload()
    }
  }, [navigate])

  const onSelect = (fulladdress) => {
    const { subdistrict, district, province, zipcode } = fulladdress
    setSubDistrict(subdistrict)
    setDistrict(district)
    setProvince(province)
    setZipcode(zipcode)
  }
  const PageDisplay = () => {
    if (docs1.objective === "สมัครใหม่" || docs1.objective === "ต่ออายุ") {
      if (page === 0) {
        return (
          <ShowCertificate
            docs1={docs1}
            role={role}
            handleChangeRole={handleChangeRole}
            handleChangeDocs1={handleChangeDocs1}
            docsAddress={docsAddress}
            handleChangeAddress={handleChangeAddress}
            createType={createType}
            handleChangeType={handleChangeType}
            headOffice={headOffice}
            branch={branch}
            setHeadOffice={setHeadOffice}
            setBranch={setBranch}
            subdistrict={subdistrict}
            setSubDistrict={setSubDistrict}
            district={district}
            setDistrict={setDistrict}
            province={province}
            setProvince={setProvince}
            zipcode={zipcode}
            setZipcode={setZipcode}
            onSelect={onSelect}
          />
        )
      } else if (page === 1) {
        return (
          <ShowContact
            isOtherAddress={isOtherAddress}
            handleChangeOtherAddress={handleChangeOtherAddress}
            sendTo={sendTo}
            sendToEmail={sendToEmail}
            setSendToEmail={setSendToEmail}
            sendToAddress={sendToAddress}
            setSendToAddress={setSendToAddress}
            sendOtherAddress={sendOtherAddress}
            setSendOtherAddress={setSendOtherAddress}
          />
        )
      } else if (page === 2) {
        return (
          <ShowService
            personalCertificate={personalCertificate}
            webServerCertificate={webServerCertificate}
            handlePsc={handlePsc}
            handleWsc={handleWsc}
            Project={Project}
            p3Details={p3Details}
            p4Details={p4Details}
            p6Details={p6Details}
            handleP4Details={handleP4Details}
            setP6Details={setP6Details}
            setP3Details={setP3Details}
            p7Details1={p7Details1}
            p7Details2={p7Details2}
            p7Details3={p7Details3}
            p7Details4={p7Details4}
            setP7Details1={setP7Details1}
            setP7Details2={setP7Details2}
            setP7Details3={setP7Details3}
            setP7Details4={setP7Details4}
            p7juristicPerson={p7juristicPerson}
            p7individualAndJuristic={p7individualAndJuristic}
            p7juristicAndRank={p7juristicAndRank}
            handleP7individualAndJuristic={handleP7individualAndJuristic}
            handleP7juristicPerson={handleP7juristicPerson}
            handleP7juristicAndRank={handleP7juristicAndRank}
            Project1={Project1}
            Project2={Project2}
            Project3={Project3}
            Project4={Project4}
            Project5={Project5}
            Project6={Project6}
            Project7={Project7}
            setProject1={setProject1}
            setProject2={setProject2}
            setProject3={setProject3}
            setProject4={setProject4}
            setProject5={setProject5}
            setProject6={setProject6}
            setProject7={setProject7}
          />
        )
      } else {
        return (
          <ShowAdditional
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadFile={handleDownloadFile}
          />
        )
      }
    } else {
      return (
        <ShowRevoke
          docs1={docs1}
          handleChangeDocs1={handleChangeDocs1}
          docsAddress={docsAddress}
          handleChangeAddress={handleChangeAddress}
          role={role}
          handleChangeRole={handleChangeRole}
          isCertificateType={isCertificateType}
          handleCertificateType={handleCertificateType}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadFile={handleDownloadFile}
        />
      )
    }
  }
  useEffect(() => {
    docs1.status = isStatus
    docs1.createType = createType
    docs1.certificateType = isCertificateType
    docsAddress.subDistrict = subdistrict
    docsAddress.district = district
    docsAddress.province = province
    docsAddress.postalCode = zipcode
    if (role === "สุโขทัย : สวรรคโลก" || role === "Sukhothai:Sawankhalok") {
      setRole("สุโขทัย : สวรรคโลก")
      docs1.officeLocation = "Sukhothai:Sawankhalok"
    } else if (
      role === "สระแก้ว : อรัญประเทศ" ||
      role === "Sa Kaeo:Aranyaprathet"
    ) {
      setRole("สระแก้ว : อรัญประเทศ")
      docs1.officeLocation = "Sa Kaeo:Aranyaprathet"
    } else if (role === "สงขลา : อำเภอเมือง" || role === "Songkhla:Mueang") {
      setRole("สงขลา : อำเภอเมือง")
      docs1.officeLocation = "Songkhla:Mueang"
    } else if (role === "สงขลา : หาดใหญ่" || role === "Songkhla:Hat Yai") {
      setRole("สงขลา : หาดใหญ่")
      docs1.officeLocation = "Songkhla:Hat Yai"
    } else if (
      role === "นครศรีธรรมราช : อำเภอเมือง" ||
      role === "Nakhon Si Thammarat:Mueang"
    ) {
      setRole("นครศรีธรรมราช : อำเภอเมือง")
      docs1.officeLocation = "Nakhon Si Thammarat:Mueang"
    } else if (role === "ยะลา : อำเภอเมือง" || role === "Yala:Mueang") {
      setRole("ยะลา : อำเภอเมือง")
      docs1.officeLocation = "Yala:Mueang"
    } else if (role === "ชลบุรี : อำเภอเมือง" || role === "Chon Buri:Mueang") {
      setRole("ชลบุรี : อำเภอเมือง")
      docs1.officeLocation = "Chon Buri:Mueang"
    } else if (
      role === "ชลบุรี : แหลมฉบัง" ||
      role === "Chon Buri:Laem Chabang"
    ) {
      setRole("ชลบุรี : แหลมฉบัง")
      docs1.officeLocation = "Chon Buri:Laem Chabang"
    } else if (
      role === "ประจวบคีรีขันธ์ : อำเภอเมือง" ||
      role === "Prachuap Khiri Khan:Mueang"
    ) {
      setRole("ประจวบคีรีขันธ์ : อำเภอเมือง")
      docs1.officeLocation = "Prachuap Khiri Khan:Mueang"
    } else if (
      role === "ประจวบคีรีขันธ์ : หัวหิน" ||
      role === "Prachuap Khiri Khan:Hua Hin"
    ) {
      setRole("ประจวบคีรีขันธ์ : หัวหิน")
      docs1.officeLocation = "Prachuap Khiri Khan:Hua Hin"
    } else if (
      role === "สิงห์บุรี : อำเภอเมือง" ||
      role === "Sing Buri:Mueang"
    ) {
      setRole("สิงห์บุรี : อำเภอเมือง")
      docs1.officeLocation = "Sing Buri:Mueang"
    } else if (role === "ระยอง : อำเภอเมือง" || role === "Rayong:Mueang") {
      setRole("ระยอง : อำเภอเมือง")
      docs1.officeLocation = "Rayong:Mueang"
    } else if (role === "ระยอง : ปลวกแดง" || role === "Rayong :Pluak Daeng") {
      setRole("ระยอง : ปลวกแดง")
      docs1.officeLocation = "Rayong :Pluak Daeng"
    } else if (role === "ระยอง : มาบตาพุด" || role === "Rayong:Map Ta Phut") {
      setRole("ระยอง : มาบตาพุด")
      docs1.officeLocation = "Rayong:Map Ta Phut"
    } else if (
      role === "อุดรธานี : อำเภอเมือง" ||
      role === "Udon Thani:Mueang"
    ) {
      setRole("อุดรธานี : อำเภอเมือง")
      docs1.officeLocation = "Udon Thani:Mueang"
    } else if (
      role === "สุราษฎร์ธานี : อำเภอเมือง" ||
      role === "Surat Thani:Mueang"
    ) {
      setRole("สุราษฎร์ธานี : อำเภอเมือง")
      docs1.officeLocation = "Surat Thani:Mueang"
    } else if (
      role === "พัทลุง : อำเภอเมือง" ||
      role === "Phatthalung:Mueang"
    ) {
      setRole("พัทลุง : อำเภอเมือง")
      docs1.officeLocation = "Phatthalung:Mueang"
    } else if (
      role === "สุพรรณบุรี : อำเภอเมือง" ||
      role === "Suphan Buri:Mueang"
    ) {
      setRole("สุพรรณบุรี : อำเภอเมือง")
      docs1.officeLocation = "Suphan Buri:Mueang"
    } else if (
      role === "ราชบุรี : อำเภอเมือง" ||
      role === "Ratchaburi:Mueang"
    ) {
      setRole("ราชบุรี : อำเภอเมือง")
      docs1.officeLocation = "Ratchaburi:Mueang"
    } else if (
      role === "สมุทรสาคร : อำเภอเมือง" ||
      role === "Samut Sakhon:Mueang"
    ) {
      setRole("สมุทรสาคร : อำเภอเมือง")
      docs1.officeLocation = "Samut Sakhon:Mueang"
    } else if (
      role === "สมุทรสาคร : กระทุ่มแบน" ||
      role === "Samut Sakhon:Krathum Baen"
    ) {
      setRole("สมุทรสาคร : กระทุ่มแบน")
      docs1.officeLocation = "Samut Sakhon:Krathum Baen"
    } else if (
      role === "สมุทรสงคราม : อำเภอเมือง" ||
      role === "Samut Songkhram:Mueang"
    ) {
      setRole("สมุทรสงคราม : อำเภอเมือง")
      docs1.officeLocation = "Samut Songkhram:Mueang"
    } else if (
      role === "สมุทรปราการ : อำเภอเมือง" ||
      role === "Samut Prakan:Mueang"
    ) {
      setRole("สมุทรปราการ : อำเภอเมือง")
      docs1.officeLocation = "Samut Prakan:Mueang"
    } else if (role === "มุกดาหาร : อำเภอเมือง" || role === "Mukdahan:Mueang") {
      setRole("มุกดาหาร : อำเภอเมือง")
      docs1.officeLocation = "Mukdahan:Mueang"
    } else if (role === "ชุมพร : อำเภอเมือง" || role === "Chumphon:Mueang") {
      setRole("ชุมพร : อำเภอเมือง")
      docs1.officeLocation = "Chumphon:Mueang"
    } else if (role === "ภูเก็ต : อำเภอเมือง" || role === "Phuket:Mueang") {
      setRole("ภูเก็ต : อำเภอเมือง")
      docs1.officeLocation = "Phuket:Mueang"
    } else if (role === "บึงกาฬ : อำเภอเมือง" || role === "Bueng Kan:Mueang") {
      setRole("บึงกาฬ : อำเภอเมือง")
      docs1.officeLocation = "Bueng Kan:Mueang"
    } else if (role === "หนองคาย : อำเภอเมือง" || role === "Nong Khai:Mueang") {
      setRole("หนองคาย : อำเภอเมือง")
      docs1.officeLocation = "Nong Khai:Mueang"
    } else if (
      role === "เชียงใหม่ : อำเภอเมือง" ||
      role === "Chiang Mai:Mueang"
    ) {
      setRole("เชียงใหม่ : อำเภอเมือง")
      docs1.officeLocation = "Chiang Mai:Mueang"
    } else if (role === "ลำพูน : อำเภอเมือง" || role === "Lamphun:Mueang") {
      setRole("ลำพูน : อำเภอเมือง")
      docs1.officeLocation = "Lamphun:Mueang"
    } else if (
      role === "อยุธยา : พระนครศรีอยุธยา" ||
      role === "Ayutthaya:Ayutthaya"
    ) {
      setRole("อยุธยา : พระนครศรีอยุธยา")
      docs1.officeLocation = "Ayutthaya:Ayutthaya"
    } else if (
      role === "ฉะเชิงเทรา : อำเภอเมือง" ||
      role === "Chachoengsao:Mueang"
    ) {
      setRole("ฉะเชิงเทรา : อำเภอเมือง")
      docs1.officeLocation = "Chachoengsao:Mueang"
    } else if (
      role === "นครสวรรค์ : อำเภอเมือง" ||
      role === "Nakhon Sawan:Mueang"
    ) {
      setRole("นครสวรรค์ : อำเภอเมือง")
      docs1.officeLocation = "Nakhon Sawan:Mueang"
    } else if (
      role === "บุรีรัมย์ : อำเภอเมือง" ||
      role === "Buri Ram:Mueang"
    ) {
      setRole("บุรีรัมย์ : อำเภอเมือง")
      docs1.officeLocation = "Buri Ram:Mueang"
    } else if (
      role === "ปราจีนบุรี : กบินทร์บุรี" ||
      role === "Prachin Buri:Kabin Buri"
    ) {
      setRole("ปราจีนบุรี : กบินทร์บุรี")
      docs1.officeLocation = "Prachin Buri:Kabin Buri"
    } else if (role === "ขอนแก่น : อำเภอเมือง" || role === "Khon Kaen:Mueang") {
      setRole("ขอนแก่น : อำเภอเมือง")
      docs1.officeLocation = "Khon Kaen:Mueang"
    } else if (
      role === "นครปฐม : อำเภอเมือง" ||
      role === "Nakhon Pathom:Mueang"
    ) {
      setRole("นครปฐม : อำเภอเมือง")
      docs1.officeLocation = "Nakhon Pathom:Mueang"
    } else if (
      role === "อุบลราชธานี : อำเภอเมือง" ||
      role === "Ubon Ratchathani:Mueang"
    ) {
      setRole("อุบลราชธานี : อำเภอเมือง")
      docs1.officeLocation = "Ubon Ratchathani:Mueang"
    } else if (role === "ลำปาง : อำเภอเมือง" || role === "Lampang:Mueang") {
      setRole("ลำปาง : อำเภอเมือง")
      docs1.officeLocation = "Lampang:Mueang"
    } else if (role === "ตาก : แม่สอด" || role === "Tak:Mae Sot") {
      setRole("ตาก : แม่สอด")
      docs1.officeLocation = "Tak:Mae Sot"
    } else if (role === "ระนอง : อำเภอเมือง" || role === "Ranong:Mueang") {
      setRole("ระนอง : อำเภอเมือง")
      docs1.officeLocation = "Ranong:Mueang"
    } else if (role === "สระบุรี : อำเภอเมือง" || role === "Saraburi:Mueang") {
      setRole("สระบุรี : อำเภอเมือง")
      docs1.officeLocation = "Saraburi:Mueang"
    } else if (
      role === "นครราชสีมา : อำเภอเมือง" ||
      role === "Nakhon Ratchasima:Mueang"
    ) {
      setRole("นครราชสีมา : อำเภอเมือง")
      docs1.officeLocation = "Nakhon Ratchasima:Mueang"
    } else if (
      role === "กาญจนบุรี : อำเภอเมือง" ||
      role === "Kanchanaburi:Mueang"
    ) {
      setRole("กาญจนบุรี : อำเภอเมือง")
      docs1.officeLocation = "Kanchanaburi:Mueang"
    } else if (role === "ปัตตานี : อำเภอเมือง" || role === "Pattani:Mueang") {
      setRole("ปัตตานี : อำเภอเมือง")
      docs1.officeLocation = "Pattani:Mueang"
    } else if (
      role === "เชียงราย : อำเภอเมือง" ||
      role === "Chiang Rai:Mueang"
    ) {
      setRole("เชียงราย : อำเภอเมือง")
      docs1.officeLocation = "Chiang Rai:Mueang"
    } else if (
      role === "จันทบุรี : อำเภอเมือง" ||
      role === "Chanthaburi:Mueang"
    ) {
      setRole("จันทบุรี : อำเภอเมือง")
      docs1.officeLocation = "Chanthaburi:Mueang"
    } else if (
      role === "ปทุมธานี : คลองหลวง" ||
      role === "Pathum Thani:Khlong Luang"
    ) {
      setRole("ปทุมธานี : คลองหลวง")
      docs1.officeLocation = "Pathum Thani:Khlong Luang"
    } else {
      setRole("กรุงเทพฯ")
      docs1.officeLocation = "HQ"
    }
  }, [
    createType,
    district,
    docs1,
    docsAddress,
    isCertificateType,
    isStatus,
    province,
    role,
    setRole,
    subdistrict,
    zipcode,
  ])

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseAround}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{ fontSize: "30px", color: "gray" }}
              onClick={handleCloseAround}
            />
          </Typography>
          {isLoading === false ? (
            <>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "60px", color: "#ffd100" }}
                />
                <ModelTextTitle>ส่งคำขอดาวน์โหลดสำเร็จ</ModelTextTitle>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: "200", textAlign: "center" }}
              ></Typography>
            </>
          ) : (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              <CircularProgress sx={{ fontSize: "60px", color: "#ffd100" }} />
              <ModelTextTitle>กรุณารอสักครู่...</ModelTextTitle>
            </Typography>
          )}
        </Box>
      </Modal>
      <HeroContainer>
        <MainContainer>
          <GoBackHeader>
            <BtnLinkLogin onClick={() => navigate(-1)}>ย้อนกลับ</BtnLinkLogin>
          </GoBackHeader>
          <InputHeader>
            <WelcomeText>รายละเอียด</WelcomeText>
            <StatusContainer>
              <LabelSecond>สถานะ &nbsp;&nbsp;</LabelSecond>
              <SelectProfile value={isStatus} onChange={handleChangeStatus}>
                {status.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </SelectProfile>
            </StatusContainer>
          </InputHeader>
          <InputContainer>
            <LabelHeader>{FormTitle[page]}</LabelHeader>
            <div>{PageDisplay()}</div>
            {docs1.objective === "สมัครใหม่" ? (
              <ButtonContainer>
                <BtnPrev
                  disabled={page === 0}
                  onClick={() => {
                    setPage((currentPage) => currentPage - 1)
                  }}
                >
                  ย้อนกลับ
                </BtnPrev>
                {page === 3 ? (
                  <BtnNext onClick={PutupdateDocs}>บันทึก</BtnNext>
                ) : (
                  <BtnNext
                    disabled={page === FormTitle.length - 1}
                    onClick={() => {
                      setPage((currentPage) => currentPage + 1)
                    }}
                  >
                    ต่อไป
                  </BtnNext>
                )}
              </ButtonContainer>
            ) : docs1.objective === "ต่ออายุ" ? (
              <ButtonContainer>
                <BtnPrev
                  disabled={page === 0}
                  onClick={() => {
                    setPage((currentPage) => currentPage - 1)
                  }}
                >
                  ย้อนกลับ
                </BtnPrev>
                {page === 3 ? (
                  <BtnNext onClick={PutupdateDocs}>บันทึก</BtnNext>
                ) : (
                  <BtnNext
                    disabled={page === FormTitle.length - 1}
                    onClick={() => {
                      setPage((currentPage) => currentPage + 1)
                    }}
                  >
                    ต่อไป
                  </BtnNext>
                )}
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <BtnPrev onClick={() => navigate(-1)}>ย้อนกลับ</BtnPrev>
                <BtnNext onClick={PutupdateDocs}>บันทึก</BtnNext>
              </ButtonContainer>
            )}
          </InputContainer>
        </MainContainer>
      </HeroContainer>
      <BeforeFooter></BeforeFooter>
    </>
  )
}

export default ShowDetails
