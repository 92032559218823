import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  margin-top: 80px;
  margin-left: 250px;
  min-height: 100vh;
  flex-direction: column;
`
export const WelcomeText = styled.h2`
  margin-top: 2rem;
  margin-left: 2rem;
  font-weight: bold;
  color: #ffd100;
`
export const RowContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 1rem;
  justify-content: space-between;
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const HeadText = styled.div`
  background: #fff;
  border-radius: 30px;
  color: #545859;
  width: 80%;
  height: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #363a3d;
  text-align: center;
`
export const TitleText = styled.div`
  margin-top: 1rem;
  color: #545859;
  width: 180px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`
export const TableCard = styled.div`
  width: 100%;
  min-height: 40vh;
  background: #fff;
  border-radius: 8px;
  color: #000;
  margin-bottom: 4rem;
  padding: 2rem;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
`
export const StatusText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #b800bc;
`
export const TableText = styled.h2`
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #545859;
`
export const LinkDetail = styled(LinkR)`
  font-weight: 300;
`
export const StatusText1 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #008fbc;
`
export const StatusText2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #00bc20;
`
export const StatusText3 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #db0d00;
`
export const StatusText4 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ffd100;
`
export const StatusText5 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #001ebc;
`
export const StatusText6 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ff7a00;
`
export const StatusText7 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #c000df;
`
export const StatusText8 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #757473;
`
export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  min-width: 30%;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #aaaaaa;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`
export const RowContainerInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
