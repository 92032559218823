import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../../api/axios"
import jwt_decode from "jwt-decode"
import { HeroContainer, WelcomeText, RowContainer } from "./customerElement"
import CustomerTable from "./CustomerTable"

const Customer = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)
  const token = localStorage.getItem("token")

  useEffect(() => {
    const user = authService.getCurrentUser()
    var role = jwt_decode(token)
    if (user && role.officeLocation === "HQ") {
      setCurrentUser(user, role)
    } else {
      authService.logout()
      navigate("/")
    }
  }, [navigate, token])
  return (
    <>
      <HeroContainer>
        <WelcomeText>Customer</WelcomeText>
        <RowContainer>
          <CustomerTable></CustomerTable>
        </RowContainer>
      </HeroContainer>
    </>
  )
}

export default Customer
