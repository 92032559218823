import React from "react"
import {
  LabelSecond,
  ContactContainer,
  Input,
  LabelModel,
  RadioYearContainer,
  YearContainer,
  SubProjectContainer,
  InputAddressSub,
} from "./showElements"
import "../Styles.css"

const ShowService = (props) => {
  return (
    <div>
      <ContactContainer>
        <LabelSecond>ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelSecond>
        <LabelModel>
          Personal Certificate โปรดระบุอีเมล์ที่แสดงบนใบรับรอง (Specify E-mail
          which it show in the digital certificate)
        </LabelModel>
        <>
          <LabelModel>*ต้องระบุ E-mail</LabelModel>
          <Input
            type="text"
            placeholder="Email"
            name="email"
            onChange={props.handlePsc}
            value={props.personalCertificate.email}
          />
          <YearContainer>
            <RadioYearContainer>
              <LabelModel>อายุการใช้บริการ : &nbsp;</LabelModel>
            </RadioYearContainer>
            <InputAddressSub
              type="text"
              placeholder="year"
              name="pyear"
              onChange={props.handlePsc}
              value={props.personalCertificate.pyear}
            />
          </YearContainer>
        </>
        <LabelModel>
          Web Server Certificate (สำหรับนำไปใช้งานกับเครื่อง Server)
          อายุการใช้บริการ (Valid time in year)
        </LabelModel>
        <>
          <YearContainer>
            <LabelSecond>
              โปรดระบุรายละเอียดในใบรับรอง เป็นภาษาอังกฤษ (Specify Certificate
              Detail)
            </LabelSecond>
            <LabelModel>Common Name (Domain Name) (CN)</LabelModel>
            <Input
              type="text"
              placeholder="Common Name"
              name="commonName"
              onChange={props.handleWsc}
              value={props.webServerCertificate.commonName}
            />
            <LabelModel>Organization Unit (OU)</LabelModel>
            <Input
              type="text"
              placeholder="Organization Unit"
              name="organizationUnit"
              onChange={props.handleWsc}
              value={props.webServerCertificate.organizationUnit}
            />
            <LabelModel>Organization (O)</LabelModel>
            <Input
              type="text"
              placeholder="Organization"
              name="organization"
              onChange={props.handleWsc}
              value={props.webServerCertificate.organization}
            />
            <LabelModel>Locality (L) </LabelModel>
            <Input
              type="text"
              placeholder="Locality"
              name="locality"
              onChange={props.handleWsc}
              value={props.webServerCertificate.locality}
            />
            <LabelModel>Country (C)</LabelModel>
            <Input
              type="text"
              placeholder="Country"
              name="country"
              onChange={props.handleWsc}
              value={props.webServerCertificate.country}
            />
            <RadioYearContainer>
              <LabelModel>อายุการใช้บริการ : &nbsp;</LabelModel>
            </RadioYearContainer>
            <InputAddressSub
              type="text"
              placeholder="year"
              name="wyear"
              onChange={props.handleWsc}
              value={props.webServerCertificate.wyear}
            />
          </YearContainer>
        </>
        <LabelSecond>
          ประเภทโครงการที่นำใบรับรองฯ ไปใช้งาน (Specify for the specific project
          which use)
        </LabelSecond>
        <label className="container">
          โครงการ NSW เช่น กรมศุลกากร , กรมประมง , อย. , กระทรวงพลังงาน ,
          กรมป่าไม้ และ กรมการค้าต่างประเทศ เป็นต้น
          <input
            type="checkbox"
            checked={props.Project1}
            onChange={() => props.setProject1(!props.Project1)}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการ สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
          (คปภ.)
          <input
            type="checkbox"
            checked={props.Project2}
            onChange={() => props.setProject2(!props.Project2)}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการกลุ่มสมาคมธนาคารไทย
          <input
            type="checkbox"
            checked={props.Project3}
            onChange={() => props.setProject3(!props.Project3)}
          />
          <span className="checkmark"></span>
        </label>
        {props.Project3 === true ? (
          <YearContainer>
            <LabelModel>ระบุ ชื่อ-นามสกุล ผู้ใช้งาน เป็นภาษาอังกฤษ</LabelModel>
            <Input
              type="text"
              placeholder="Fisrt Name - Last Name"
              name="p3Details"
              onChange={(e) => props.setP3Details(e.target.value)}
              value={props.p3Details}
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการสำนักงานพาณิชย์จังหวัด
          <input
            type="checkbox"
            checked={props.Project4}
            onChange={() => props.setProject4(!props.Project4)}
          />
          <span className="checkmark"></span>
        </label>
        {props.Project4 === true ? (
          <YearContainer>
            <LabelModel>ระบุ ชื่อ-นามสกุล ผู้ใช้งาน เป็นภาษาอังกฤษ</LabelModel>
            <Input
              type="text"
              placeholder="Fisrt Name - Last Name"
              name="name"
              onChange={props.handleP4Details}
              value={props.p4Details.name}
            />
            <LabelModel>เลขที่บัตรประชาชนของผู้ใช้งาน</LabelModel>
            <Input
              type="text"
              placeholder="เลขที่บัตร"
              name="idCard"
              onChange={props.handleP4Details}
              value={props.p4Details.idCard}
            />
            <LabelModel>บัตรหมดอายุ</LabelModel>
            <Input
              type="text"
              placeholder="บัตรหมดอายุ"
              name="expireDate"
              onChange={props.handleP4Details}
              value={props.p4Details.expireDate}
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการสำนักงานป้องกันและปราบปรามการฟอกเงิน (ปปง.)
          <input
            type="checkbox"
            checked={props.Project5}
            onChange={() => props.setProject5(!props.Project5)}
          />
          <span className="checkmark"></span>
        </label>
        <label className="container">
          โครงการอื่นๆ
          <input
            type="checkbox"
            checked={props.Project6}
            onChange={() => props.setProject6(!props.Project6)}
          />
          <span className="checkmark"></span>
        </label>
        {props.Project6 === true ? (
          <YearContainer>
            <LabelModel>ระบุโครงการ</LabelModel>
            <Input
              type="text"
              placeholder="ระบุโครงการ"
              name="p6Details"
              onChange={(e) => props.setP6Details(e.target.value)}
              value={props.p6Details}
            />
          </YearContainer>
        ) : null}
        <label className="container">
          โครงการ RDC Online ของ สวทช.
          <input
            type="checkbox"
            checked={props.Project7}
            onChange={() => props.setProject7(!props.Project7)}
          />
          <span className="checkmark"></span>
        </label>
        {props.Project7 === true ? (
          <YearContainer>
            <LabelSecond>
              โปรดเลือกรูปแบบที่นำไปใช้พร้อมระบุข้อมูลเป็นภาษาอังกฤษ
            </LabelSecond>
            <SubProjectContainer>
              <label className="container">
                แบบที่ 1 บุคคลธรรมดา
                <input
                  type="checkbox"
                  checked={props.p7Details1}
                  onChange={() => props.setP7Details1(!props.p7Details1)}
                />
                <span className="checkmark"></span>
              </label>
              <label className="container">
                แบบที่ 2 นิติบุคคล
                <input
                  type="checkbox"
                  checked={props.p7Details2}
                  onChange={() => props.setP7Details2(!props.p7Details2)}
                />
                <span className="checkmark"></span>
              </label>
              {props.p7Details2 === true ? (
                <YearContainer>
                  <LabelModel>
                    ชื่อนิติบุคคลเจ้าของใบรับรอง (CN)และ (O)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.juristicName}
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.juristicUID}
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7juristicPerson}
                    value={props.p7juristicPerson.province}
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 3 นิติบุคคล + บุคคล
                <input
                  type="checkbox"
                  checked={props.p7Details3}
                  onChange={() => props.setP7Details3(!props.p7Details3)}
                />
                <span className="checkmark"></span>
              </label>
              {props.p7Details3 === true ? (
                <YearContainer>
                  <LabelModel>E-mail เจ้าหน้าที่ของนิติบุคคล (E)</LabelModel>
                  <Input
                    type="text"
                    placeholder="E-mail"
                    name="functionaryEmail"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.functionaryEmail}
                  />
                  <LabelModel>
                    ชื่อเจ้าหน้าที่นิติบุคคลเจ้าของใบรับรอง (CN)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.juristicName}
                  />
                  <LabelModel>
                    ชื่อแผนกของเจ้าหน้าที่นิติบุคคลที่ระบุ (ถ้ามี) (OU)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="subdivision"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.subdivision}
                  />
                  <LabelModel>ชื่อนิติบุคคลเจ้าของใบรับรอง (O)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อนิติบุคคลเจ้าของใบรับรอง"
                    name="functionaryName"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.functionaryName}
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="idCard"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.idCard}
                  />
                  <LabelModel>
                    เลขประจำตัวประชาชนของเจ้าหน้าที่นิติบุคคลที่ระบุ (UID)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.juristicUID}
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7individualAndJuristic}
                    value={props.p7individualAndJuristic.province}
                  />
                </YearContainer>
              ) : null}
              <label className="container">
                แบบที่ 4 นิติบุคคล + ตำแหน่ง
                <input
                  type="checkbox"
                  checked={props.p7Details4}
                  onChange={() => props.setP7Details4(!props.p7Details4)}
                />
                <span className="checkmark"></span>
              </label>
              {props.p7Details4 === true ? (
                <YearContainer>
                  <LabelModel>
                    ชื่อตำแหน่งของเจ้าหน้าที่นิติบุคคลเจ้าของใบรับรอง (CN)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="subdivision"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.subdivision}
                  />
                  <LabelModel>
                    ชื่อแผนกของเจ้าหน้าที่ นิติบุคคลที่ระบุ (ถ้ามี) (OU)
                  </LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="juristicName"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.juristicName}
                  />
                  <LabelModel>ชื่อนิติบุคคลเจ้าของใบรับรอง (O)</LabelModel>
                  <Input
                    type="text"
                    placeholder="ชื่อ-สกุล"
                    name="name"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.name}
                  />
                  <LabelModel>เลขทะเบียนนิติบุคคล (UID)</LabelModel>
                  <Input
                    type="text"
                    placeholder="Ex. 1579900123456"
                    name="juristicUID"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.juristicUID}
                  />
                  <LabelModel>จังหวัดของนิติบุคคล (L)</LabelModel>
                  <Input
                    type="text"
                    placeholder="จังหวัด"
                    name="province"
                    onChange={props.handleP7juristicAndRank}
                    value={props.p7juristicAndRank.province}
                  />
                </YearContainer>
              ) : null}
            </SubProjectContainer>
          </YearContainer>
        ) : null}
      </ContactContainer>
    </div>
  )
}

export default ShowService
