import React, { useState, useEffect } from "react"
import "./Styles.css"
import IndexTable from "./IndexTable"
import { useNavigate } from "react-router-dom"
import authService from "../../api/axios"
import { HeroContainer, WelcomeText, RowContainer } from "./indexElements"
import { BtnAdd, BtnCreate } from "../Admin/adminElements"
import { Button } from "@mui/material"
import FormDownloadSVG from "./FormDownloadCSV"

const Index = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/signin")
    }
  }, [navigate])
  return (
    <>
      <HeroContainer>
      <WelcomeText>Overview</WelcomeText>
      <FormDownloadSVG/>
        <RowContainer>
          <IndexTable></IndexTable>
        </RowContainer>
      </HeroContainer>
    </>
  )
}

export default Index
