import React from "react"
import {
  InputColumn,
  Input,
  RowContainerInput,
  LabelModel,
  InputRow,
  RadioContainer,
  LabelSecond,
  SelectProfile,
  ContactContainer,
  SubProjectContainer,
  LabelHeader,
  BtnDownloadPDF,
} from "./showElements"

const officeLocation = [
  "HQ",
  "Sukhothai:Sawankhalok",
  "Sa Kaeo:Aranyaprathet",
  "Songkhla:Mueang",
  "Songkhla:Hat Yai",
  "Nakhon Si Thammarat:Mueang",
  "Yala:Mueang",
  "Chon Buri:Mueang",
  "Chon Buri:Laem Chabang",
  "Prachuap Khiri Khan:Mueang",
  "Prachuap Khiri Khan:Hua Hin",
  "Sing Buri:Mueang",
  "Rayong:Mueang",
  "Rayong :Pluak Daeng",
  "Rayong:Map Ta Phut",
  "Udon Thani:Mueang",
  "Surat Thani:Mueang",
  "Phatthalung:Mueang",
  "Suphan Buri:Mueang",
  "Ratchaburi:Mueang",
  "Samut Sakhon:Mueang",
  "Samut Sakhon:Krathum Baen",
  "Samut Songkhram:Mueang",
  "Samut Prakan:Mueang",
  "Mukdahan:Mueang",
  "Chumphon:Mueang",
  "Phuket:Mueang",
  "Bueng Kan:Mueang",
  "Nong Khai:Mueang",
  "Chiang Mai:Mueang",
  "Lamphun:Mueang",
  "Ayutthaya:Ayutthaya",
  "Chachoengsao:Mueang",
  "Nakhon Sawan:Mueang",
  "Buri Ram:Mueang",
  "Prachin Buri:Kabin Buri",
  "Khon Kaen:Mueang",
  "Nakhon Pathom:Mueang",
  "Ubon Ratchathani:Mueang",
  "Lampang:Mueang",
  "Tak:Mae Sot",
  "Ranong:Mueang",
  "Saraburi:Mueang",
  "Nakhon Ratchasima:Mueang",
  "Kanchanaburi:Mueang",
  "Pattani:Mueang",
  "Chiang Rai:Mueang",
  "Chanthaburi:Mueang",
  "Pathum Thani:Khlong Luang",
]
const certificateType = ["ใบรับรองบุลคล", "ใบรับรองเว็บไซต์"]
const ShowRevoke = (props) => {
  return (
    <>
      <RadioContainer>
        <LabelSecond>ประเภทการสมัคร (Type): &nbsp;</LabelSecond>
        <LabelModel>{props.docs1.objective}</LabelModel>
      </RadioContainer>
      <LabelModel> ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelModel>
      <RowContainerInput>
        <SelectProfile
          value={props.isCertificateType}
          onChange={props.handleCertificateType}
          multiple={false}
        >
          {certificateType.map((certificateType) => (
            <option key={certificateType} value={certificateType}>
              {certificateType}
            </option>
          ))}
        </SelectProfile>
        <SelectProfile value={props.role} onChange={props.handleChangeRole}>
          {officeLocation.map((officeLocation) => (
            <option key={officeLocation} value={officeLocation}>
              {officeLocation}
            </option>
          ))}
        </SelectProfile>
      </RowContainerInput>
      <LabelModel>หมายเลขใบรับรองฯ</LabelModel>
      <Input
        type="text"
        placeholder="หมายเลขใบรับรอง"
        name="certificateNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.certificateNo}
      />
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาไทย (First Name – Last Name in Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="fullNameTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameTH}
      />
      <LabelModel>
        ชื่อ-นามสกุล เป็นภาษาอังกฤษ (First Name – Last Name in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="fullNameEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.fullNameEN}
      />
      <LabelModel>เลขประจําตัวผู้เสียภาษีอากร</LabelModel>
      <Input
        type="text"
        placeholder="Tax No."
        name="taxNo"
        onChange={props.handleChangeDocs1}
        value={props.docs1.taxNo}
      />
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="birthday"
            onChange={props.handleChangeDocs1}
            value={props.docs1.birthday}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>วันออกบัตร</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="startDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.startDate}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>บัตรหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="endDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.endDate}
          />
        </InputColumn>
      </RowContainerInput>
      <RowContainerInput>
        <InputColumn>
          <LabelModel>หนังสือเดินทางเลขที่</LabelModel>
          <InputRow
            type="input"
            placeholder="หนังสือเดินทางเลขที่"
            name="passport"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passport}
          />
        </InputColumn>
        <InputColumn>
          <LabelModel>วันหมดอายุ</LabelModel>
          <InputRow
            type="date"
            placeholder="เช่น 01/01/2565"
            name="passportExpire"
            onChange={props.handleChangeDocs1}
            value={props.docs1.passportExpire}
          />
        </InputColumn>
      </RowContainerInput>
      <LabelModel>
        ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย (Juristic Person/Organization Name in
        Thai)
      </LabelModel>
      <Input
        type="text"
        placeholder="ชื่อ-นามสกุล"
        name="juristicPersonOrOrganizationTH"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationTH}
        disabled
      />
      <LabelModel>
        ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ (Juristic Person/Organization Name
        in English)
      </LabelModel>
      <Input
        type="text"
        placeholder="First Name-Last Name"
        name="juristicPersonOrOrganizationEN"
        onChange={props.handleChangeDocs1}
        value={props.docs1.juristicPersonOrOrganizationEN}
        disabled
      />
      <LabelHeader>เอกสารประกอบคําร้องขอ</LabelHeader>
      <ContactContainer>
        <SubProjectContainer>
          <BtnDownloadPDF onClick={props.handleDownloadPDF}>
            Download PDF
          </BtnDownloadPDF>
        </SubProjectContainer>
        <SubProjectContainer>
          <BtnDownloadPDF onClick={props.handleDownloadFile}>
            Download File
          </BtnDownloadPDF>
        </SubProjectContainer>
      </ContactContainer>
    </>
  )
}

export default ShowRevoke
