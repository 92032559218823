import axios from "axios"
import fileDownload from "js-file-download"
import { saveAs } from "file-saver"
const BASE_URL = "https://api.ntca.co.th/v1/admin"

const createAdmin = (
  email,
  password,
  firstName,
  lastName,
  officeLocation,
  phone
) => {
  return axios
    .post(BASE_URL + "/register", {
      email,
      password,
      firstName,
      lastName,
      officeLocation,
      phone,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", JSON.stringify(response.data))
      }

      return response.data
    })
}

const login = (email, password) => {
  return axios
    .post(BASE_URL + "/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token)
      }

      return response.data
    })
}
const forgotPassword = (email) => {
  return axios.post(BASE_URL + "/forgot-password", {
    email,
  })
}
const logout = () => {
  localStorage.removeItem("token")
}

const getCurrentUser = () => {
  return localStorage.getItem("token")
}

const getAdmindata = (token) => {
  return axios.get(BASE_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getAdminInfo = (token, adminId) => {
  return axios.get(BASE_URL + "/info/" + adminId, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getDocuments = (token) => {
  return axios.get(BASE_URL + "/documents", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getDownloadPDF = (token, id) => {
  return axios
    .get(BASE_URL + "/download/pdf?documentId=" + id, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      fileDownload(res.data, "certificate.pdf")
    })
}
const getDownloadFile = (token, id) => {
  return axios
    .get("https://api.ntca.co.th/v1/download/files?documentId=" + id, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/zip",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      console.log(res.data)
      saveAs(res.data, "file.zip")
    })
}
const getDocumentId = (token, id) => {
  return axios.get(BASE_URL + "/documents/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getDownloadCSV= (token, startDate, endDate) => {
  return axios.get(`https://api.ntca.co.th/v1/generate/csv?startDate=${startDate}&endDate=${endDate}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    console.log(res.data);
    fileDownload(res.data, "Cert_export.csv","Content-Type=text/csv; charset=utf-8",
    "\uFEFF");
  })
}

const getCustomerdata = (token) => {
  return axios.get(BASE_URL + "/customers", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const getCustomerInfo = (token, cutomerId) => {
  return axios.get(BASE_URL + "/users/info/" + cutomerId, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const updateAdmin = (
  token,
  id,
  firstName,
  lastName,
  phoneNumber,
  officeLocation
) => {
  const data = {
    firstName: firstName,
    lastName: lastName,
    phone: phoneNumber,
  }
  return axios.put(BASE_URL + "/" + id, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}
const updateCustomer = (token, id, values, address) => {
  const data = {
    address: {
      postcode: address.postcode,
      buildingName: address.buildingName,
      houseNo: address.houseNo,
      street: address.street,
      district: address.district,
      subDistrict: address.subDistrict,
      province: address.province,
      countryID: address.countryID,
      moo: address.moo,
      village: address.village,
      alley: address.alley,
    },
    firstName: values.firstName,
    lastName: values.lastName,
  }
  return axios.put(BASE_URL + "/customers/" + id, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

const updateDocs = (
  id,
  token,
  docs1,
  docsAddress,
  isOtherAddress,
  sendToEmail,
  sendToAddress,
  personalCertificate,
  webServerCertificate,
  Project1,
  Project2,
  Project3,
  Project4,
  Project5,
  Project6,
  Project7,
  p3Details,
  p4Details,
  p6Details,
  p7Details1,
  p7Details2,
  p7Details3,
  p7Details4,
  p7juristicPerson,
  p7individualAndJuristic,
  p7juristicAndRank
) => {
  const data = {
    fullNameTH: docs1.fullNameTH,
    fullNameEN: docs1.fullNameEN,
    createType: docs1.createType,
    objective: docs1.objective,
    juristicPersonOrOrganizationTH: docs1.juristicPersonOrOrganizationTH,
    juristicPersonOrOrganizationEN: docs1.juristicPersonOrOrganizationEN,
    taxNo: docs1.taxNo,
    businessType: docs1.businessType,
    registrationDate: docs1.registrationDate,
    cardId: docs1.cardId,
    startDate: docs1.startDate,
    endDate: docs1.endDate,
    passport: docs1.passport,
    country: docs1.country,
    passportExpire: docs1.passportExpire,
    occupation: docs1.occupation,
    birthday: docs1.birthday,
    status: docs1.status,
    officeLocation: docs1.officeLocation,
    certificateNo: docs1.certificateNo,
    certificateType: docs1.certificateType,
    addressFromCertificate: {
      headOffice: docsAddress.headOffice,
      branch: docsAddress.branch,
      branchNo: docsAddress.branchNo,
      houseNo: docsAddress.houseNo,
      moo: docsAddress.moo,
      building: docsAddress.building,
      soi: docsAddress.soi,
      street: docsAddress.street,
      subDistrict: docsAddress.subDistrict,
      district: docsAddress.district,
      province: docsAddress.province,
      postalCode: docsAddress.postalCode,
      nameCoordinator: docsAddress.nameCoordinator,
      phoneNumber: docsAddress.phoneNumber,
      email: docsAddress.email,
    },
    serviceDescription: {
      personalCertificate: {
        email: personalCertificate.email,
        year: personalCertificate.pyear,
      },
      webServerCertificate: {
        commonName: webServerCertificate.commonName,
        organizationUnit: webServerCertificate.organizationUnit,
        organization: webServerCertificate.organization,
        locality: webServerCertificate.locality,
        country: webServerCertificate.country,
        year: webServerCertificate.wyear,
      },
    },
    informingCharge: {
      sendToEmail: sendToEmail,
      sendToAddress: sendToAddress,
      otherMailingAddress: {
        houseNo: isOtherAddress.houseNo,
        postCode: isOtherAddress.postCode,
        building: isOtherAddress.building,
        street: isOtherAddress.street,
        moo: isOtherAddress.moo,
        alley: isOtherAddress.alley,
        subDistrict: isOtherAddress.subDistrict,
        district: isOtherAddress.district,
        province: isOtherAddress.province,
        phoneNumber: isOtherAddress.phoneNumber,
        fax: isOtherAddress.fax,
      },
    },
    project1: {
      isCheck: Project1,
      name: "none",
    },
    project2: {
      isCheck: Project2,
      name: "none",
    },
    project3: {
      isCheck: Project3,
      name: p3Details,
    },
    project4: {
      isCheck: Project4,
      name: p4Details.name,
      idCard: p4Details.idCard,
      expireDate: p4Details.expireDate,
    },
    project5: {
      isCheck: Project5,
      name: "none",
    },
    project6: {
      isCheck: Project6,
      name: p6Details,
    },
    project7: {
      isCheck: Project7,
      name: "none",
      forIndividual: {
        check: p7Details1,
      },
      juristicPerson: {
        check: p7Details2,
        province: p7juristicPerson.province,
        juristicName: p7juristicPerson.juristicName,
        juristicUID: p7juristicPerson.juristicUID,
      },
      individualAndJuristic: {
        check: p7Details3,
        functionaryEmail: p7individualAndJuristic.functionaryEmail,
        functionaryName: p7individualAndJuristic.functionaryName,
        subdivision: p7individualAndJuristic.subdivision,
        juristicName: p7individualAndJuristic.juristicName,
        juristicUID: p7individualAndJuristic.juristicUID,
        idCard: p7individualAndJuristic.idCard,
        province: p7individualAndJuristic.province,
      },
      juristicAndRank: {
        check: p7Details4,
        name: p7juristicAndRank.name,
        subdivision: p7juristicAndRank.subdivision,
        juristicName: p7juristicAndRank.juristicName,
        juristicUID: p7juristicAndRank.juristicUID,
        province: p7juristicAndRank.province,
      },
    },
  }
  return axios.put(BASE_URL + "/documents/" + id, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
}

const authService = {
  login,
  logout,
  getCurrentUser,
  forgotPassword,
  getAdmindata,
  getCustomerdata,
  createAdmin,
  getAdminInfo,
  updateAdmin,
  getDocuments,
  getDocumentId,
  getCustomerInfo,
  updateCustomer,
  updateDocs,
  getDownloadPDF,
  getDownloadFile,
  getDownloadCSV,
}

export default authService
