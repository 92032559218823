import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../api/axios"
import Index from "../components/Index/Index"

const IndexPage = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = authService.getCurrentUser()

    if (user) {
      setCurrentUser(user)
    } else {
      navigate("/")
      window.location.reload()
    }
  }, [navigate])

  return (
    <>
      <Index />
    </>
  )
}

export default IndexPage
