import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const Nav = styled.nav`
 background: #fff;
 height: 80px;
 width: 100%;
 justify-content: center;
 font-size: 1rem;
 position: fixed;
 top: 0;
 z-index: 10;

 @media screen and (max-width: 960px){
     transition 0.8s all ease;
 }
`
//  box-shadow: 0 1px 10px 0 #F0F0F0;
export const NavbarContainer = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
`
export const NavRow = styled.div`
  display: flex;
  list-style: none;
  text-align: center;
`
export const NavbarLogo = styled(LinkR)`
  color: #ffd100;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
`
export const NavSpac = styled.nav`
  margin-right: 2rem;
`
export const BtnLinkLogin = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 15px 10px;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BtnLinkRegis = styled(LinkR)`
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffd100;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  margin: 15px 10px;
  color: #ffd100;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ffd100;
  }
`
export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding: 1rem;
  border: 1px solid #ffd100;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const DropdrowBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #ffd100;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
`
