import React from "react"
import InputAddress from "react-thailand-address-autocomplete"
import {
  RadioContainer,
  LabelModel,
  LabelSecond,
  Input,
  SelectProfile,
  RowContainerInput,
  InputColumn,
  InputRow,
  RowContainerBranch,
  RowInputAutoaddress,
} from "./showElements"
const isCheck = "บุคคลธรรมดา"

const officeLocation = [
  "กรุงเทพฯ",
  "สุโขทัย : สวรรคโลก",
  "สระแก้ว : อรัญประเทศ",
  "สงขลา : อำเภอเมือง",
  "สงขลา : หาดใหญ่",
  "นครศรีธรรมราช : อำเภอเมือง",
  "ยะลา : อำเภอเมือง",
  "ชลบุรี : อำเภอเมือง",
  "ชลบุรี : แหลมฉบัง",
  "ประจวบคีรีขันธ์ : อำเภอเมือง",
  "ประจวบคีรีขันธ์ : หัวหิน",
  "สิงห์บุรี : อำเภอเมือง",
  "ระยอง : อำเภอเมือง",
  "ระยอง : ปลวกแดง",
  "ระยอง : มาบตาพุด",
  "อุดรธานี : อำเภอเมือง",
  "สุราษฎร์ธานี : อำเภอเมือง",
  "พัทลุง : อำเภอเมือง",
  "สุพรรณบุรี : อำเภอเมือง",
  "ราชบุรี : อำเภอเมือง",
  "สมุทรสาคร : อำเภอเมือง",
  "สมุทรสาคร : กระทุ่มแบน",
  "สมุทรสงคราม : อำเภอเมือง",
  "สมุทรปราการ : อำเภอเมือง",
  "มุกดาหาร : อำเภอเมือง",
  "ชุมพร : อำเภอเมือง",
  "ภูเก็ต : อำเภอเมือง",
  "บึงกาฬ : อำเภอเมือง",
  "หนองคาย : อำเภอเมือง",
  "เชียงใหม่ : อำเภอเมือง",
  "ลำพูน : อำเภอเมือง",
  "อยุธยา : พระนครศรีอยุธยา",
  "ฉะเชิงเทรา : อำเภอเมือง",
  "นครสวรรค์ : อำเภอเมือง",
  "บุรีรัมย์ : อำเภอเมือง",
  "ปราจีนบุรี : กบินทร์บุรี",
  "ขอนแก่น : อำเภอเมือง",
  "นครปฐม : อำเภอเมือง",
  "อุบลราชธานี : อำเภอเมือง",
  "ลำปาง : อำเภอเมือง",
  "ตาก : แม่สอด",
  "ระนอง : อำเภอเมือง",
  "สระบุรี : อำเภอเมือง",
  "นครราชสีมา : อำเภอเมือง",
  "กาญจนบุรี : อำเภอเมือง",
  "ปัตตานี : อำเภอเมือง",
  "เชียงราย : อำเภอเมือง",
  "จันทบุรี : อำเภอเมือง",
  "ปทุมธานี : คลองหลวง",
]
const createType = ["บุคคลธรรมดา", "นิติบุคคล", "ภาครัฐ"]

const ShowCertificate = (props) => {
  return (
    <>
      <RadioContainer>
        <LabelSecond>ประเภทการสมัคร (Type): &nbsp;</LabelSecond>
        <LabelModel>{props.docs1.objective}</LabelModel>
      </RadioContainer>
      <LabelModel> ประเภทใบรับรองฯ ที่ขอใช้บริการ</LabelModel>
      <RowContainerInput>
        <SelectProfile
          value={props.createType}
          onChange={props.handleChangeType}
        >
          {createType.map((createType) => (
            <option key={createType} value={createType}>
              {createType}
            </option>
          ))}
        </SelectProfile>
        <SelectProfile value={props.role} onChange={props.handleChangeRole}>
          {officeLocation.map((officeLocation) => (
            <option key={officeLocation} value={officeLocation}>
              {officeLocation}
            </option>
          ))}
        </SelectProfile>
      </RowContainerInput>
      {props.docs1.createType === isCheck ? (
        <div>
          <LabelModel>
            ชื่อ-นามสกุล เป็นภาษาไทย (First Name – Last Name in Thai)
          </LabelModel>
          <Input
            type="text"
            placeholder="ชื่อ-นามสกุล"
            name="fullNameTH"
            onChange={props.handleChangeDocs1}
            value={props.docs1.fullNameTH}
          />
          <LabelModel>
            ชื่อ-นามสกุล เป็นภาษาอังกฤษ (First Name – Last Name in English)
          </LabelModel>
          <Input
            type="text"
            placeholder="First Name-Last Name"
            name="fullNameEN"
            onChange={props.handleChangeDocs1}
            value={props.docs1.fullNameEN}
          />
          <LabelModel>เลขที่บัตรประชาชน (Citizen ID Card No)</LabelModel>
          <Input
            type="text"
            placeholder="เลขบัตรประชาชน"
            name="cardId"
            onChange={props.handleChangeDocs1}
            value={props.docs1.cardId}
          />
          <LabelModel>อาชีพ (Occupation)</LabelModel>
          <Input
            type="text"
            placeholder="อาชีพ"
            name="occupation"
            onChange={props.handleChangeDocs1}
            value={props.docs1.occupation}
          />
          <RowContainerInput>
            <InputColumn>
              <LabelModel>วัน/เดือน/ปีเกิด (Date of Birth)</LabelModel>
              <InputRow
                type="date"
                placeholder="เช่น 01/01/2565"
                name="birthday"
                onChange={props.handleChangeDocs1}
                value={props.docs1.birthday}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>บัตรหมดอายุ</LabelModel>
              <InputRow
                type="date"
                placeholder="เช่น 01/01/2565"
                name="endDate"
                onChange={props.handleChangeDocs1}
                value={props.docs1.endDate}
              />
            </InputColumn>
          </RowContainerInput>
          <LabelSecond>
            ที่อยู่ตามทะเบียนบ้าน (Address as specified in the Household
            Registration Certificate)
          </LabelSecond>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>เลขที่ (House No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="เลขที่"
                name="houseNo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.houseNo}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>หมู่ที่ (Moo)</LabelModel>
              <InputRow
                type="text"
                placeholder="หมู่ที่"
                name="moo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.moo}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>อาคาร/หมู่บ้าน (Building No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="หมู่บ้าน "
                name="building"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.building}
              />
            </InputColumn>
          </RowContainerInput>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
              <InputRow
                type="text"
                placeholder="ตรอก/ซอย"
                name="soi"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.soi}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>ถนน (Street)</LabelModel>
              <InputRow
                type="text"
                placeholder="ถนน"
                name="street"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.street}
              />
            </InputColumn>
          </RowContainerInput>
          <RowInputAutoaddress>
            <InputColumn>
              <LabelModel>แขวง (Sub District)</LabelModel>
              <InputAddress
                style={{
                  width: "155%",
                  height: "3rem",
                  border: "1px solid #545859",
                  outlineStyle: "none",
                  borderRadius: "8px",
                  color: "#3c354e",
                }}
                placeholder="แขวง / ตำบล"
                address="subdistrict"
                name="subdistrict"
                value={props.subdistrict}
                onChange={(e) => props.setSubDistrict(e.target.value)}
                onSelect={props.onSelect}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>เขต/อำเภอ (District)</LabelModel>
              <InputAddress
                style={{
                  width: "155%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="เขต / อำเภอ"
                address="district"
                name="district"
                value={props.district}
                onChange={(e) => props.setDistrict(e.target.value)}
                onSelect={props.onSelect}
              />
            </InputColumn>
          </RowInputAutoaddress>
          <RowInputAutoaddress>
            <InputColumn>
              <LabelModel>จังหวัด (Province)</LabelModel>
              <InputAddress
                style={{
                  width: "155%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="จังหวัด"
                address="province"
                name="province"
                value={props.province}
                onChange={(e) => props.setProvince(e.target.value)}
                onSelect={props.onSelect}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
              <InputAddress
                style={{
                  width: "155%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="เลขไปรษณีย์"
                address="zipcode"
                name="zipcode"
                value={props.zipcode}
                onChange={(e) => props.setZipcode(e.target.value)}
                onSelect={props.onSelect}
              />
            </InputColumn>
          </RowInputAutoaddress>
          <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
          <Input
            type="text"
            placeholder="เบอร์โทร"
            name="phoneNumber"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.phoneNumber}
          />
          <LabelModel>อีเมล์ (E-mail)</LabelModel>
          <Input
            type="text"
            placeholder="อีเมล์"
            name="email"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.email}
          />
        </div>
      ) : (
        <div>
          <LabelModel>
            ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาไทย (Juristic Person/Organization
            Name in Thai)
          </LabelModel>
          <Input
            type="text"
            placeholder="ชื่อ-นามสกุล"
            name="juristicPersonOrOrganizationTH"
            onChange={props.handleChangeDocs1}
            value={props.docs1.juristicPersonOrOrganizationTH}
          />
          <LabelModel>
            ชื่อนิติบุคคล/หน่วยงาน เป็นภาษาอังกฤษ (Juristic Person/Organization
            Name in English)
          </LabelModel>
          <Input
            type="text"
            placeholder="First Name-Last Name"
            name="juristicPersonOrOrganizationEN"
            onChange={props.handleChangeDocs1}
            value={props.docs1.juristicPersonOrOrganizationEN}
          />
          <LabelModel>
            เลขเสียภาษีของนิติบุคคล (Certificate of Juristic Person
            Registration)
          </LabelModel>
          <Input
            type="text"
            placeholder="เลขเสียภาษี"
            name="taxNo"
            onChange={props.handleChangeDocs1}
            value={props.docs1.taxNo}
          />
          <LabelModel>ประเภทธุรกิจ (Business Type)</LabelModel>
          <Input
            type="text"
            placeholder="ธุรกิจ"
            name="businessType"
            onChange={props.handleChangeDocs1}
            value={props.docs1.businessType}
          />
          <LabelModel>วันที่จดทะเบียน (Registration Date)</LabelModel>
          <Input
            type="text"
            placeholder="Ex. 01/01/2565"
            name="registrationDate"
            onChange={props.handleChangeDocs1}
            value={props.docs1.registrationDate}
          />
          <LabelSecond>
            ที่อยู่ตามหนังสือรับรองการจดทะเบียนนิติบุคคล/หน่วยงาน (Address from
            certificate of Juristic Person or Address of Government)
          </LabelSecond>
          <RowContainerBranch>
            <RowContainerBranch>
              <label className="container">
                สำนักงานใหญ่ (Head Office)
                <input
                  type="checkbox"
                  name="headOffice"
                  checked={props.headOffice}
                  onChange={() => props.setHeadOffice(!props.headOffice)}
                />
                <span className="checkmark"></span>
              </label>
            </RowContainerBranch>
            <RowContainerBranch>
              <label className="container">
                สาขา (Branch)
                <input
                  type="checkbox"
                  name="branch"
                  checked={props.branch}
                  onChange={() => props.setBranch(!props.branch)}
                />
                <span className="checkmark"></span>
              </label>
            </RowContainerBranch>
          </RowContainerBranch>
          {props.branch === true ? (
            <div>
              <LabelModel>รหัสสาขา (Branch No.)</LabelModel>
              <Input
                type="text"
                placeholder="รหัสสาขา"
                name="branchNo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.branchNo}
              />
            </div>
          ) : null}
          <RowContainerInput>
            <InputColumn>
              <LabelModel>เลขที่ (House No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="เลขที่"
                name="houseNo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.houseNo}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>หมู่ที่ (Moo)</LabelModel>
              <InputRow
                type="text"
                placeholder="หมู่ที่"
                name="moo"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.moo}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>อาคาร/ชั้น/เลขห้อง (Building No.)</LabelModel>
              <InputRow
                type="text"
                placeholder="อาคาร/ชั้น/เลขห้อง"
                name="building"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.building}
              />
            </InputColumn>
          </RowContainerInput>
          <RowContainerInput>
            <InputColumn>
              <LabelModel>ตรอก/ซอย (Soi)</LabelModel>
              <InputRow
                type="text"
                placeholder="ตรอก/ซอย"
                name="soi"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.soi}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>ถนน (Street)</LabelModel>
              <InputRow
                type="text"
                placeholder="ถนน"
                name="street"
                onChange={props.handleChangeAddress}
                value={props.docsAddress.street}
              />
            </InputColumn>
          </RowContainerInput>
          <RowInputAutoaddress>
            <InputColumn>
              <LabelModel>แขวง (Sub District)</LabelModel>
              <InputAddress
                style={{
                  width: "155%",
                  height: "3rem",
                  border: "1px solid #545859",
                  outlineStyle: "none",
                  borderRadius: "8px",
                  color: "#3c354e",
                }}
                placeholder="แขวง / ตำบล"
                address="subdistrict"
                name="subdistrict"
                value={props.subdistrict}
                onChange={(e) => props.setSubDistrict(e.target.value)}
                onSelect={props.onSelect}
              />
            </InputColumn>
            <InputColumn>
              <LabelModel>เขต/อำเภอ (District)</LabelModel>
              <InputAddress
                style={{
                  width: "155%",
                  height: "3rem",
                  border: "1px solid #545859",
                  borderRadius: "8px",
                  outlineStyle: "none",
                  color: "#3c354e",
                }}
                placeholder="เขต / อำเภอ"
                address="district"
                name="district"
                value={props.district}
                onChange={(e) => props.setDistrict(e.target.value)}
                onSelect={props.onSelect}
              />
            </InputColumn>
          </RowInputAutoaddress>
          <LabelModel>จังหวัด (Province)</LabelModel>
          <Input
            type="text"
            placeholder="จังหวัด"
            name="province"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.province}
          />
          <LabelModel>รหัสไปรษณีย์ (Postal Code)</LabelModel>
          <Input
            type="text"
            placeholder="รหัสไปรษณีย์"
            name="postalCode"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.postalCode}
          />
          <LabelModel>ชื่อ-สกุล ผู้ประสานงาน (Name Coordinator)</LabelModel>
          <Input
            type="text"
            placeholder="ผู้ประสานงาน"
            name="nameCoordinator"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.nameCoordinator}
          />
          <LabelModel>หมายเลขติดต่อ (Mobile/Telephone)</LabelModel>
          <Input
            type="text"
            placeholder="เบอร์ติดต่อ"
            name="phoneNumber"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.phoneNumber}
          />
          <LabelModel>อีเมล์ (E-mail)</LabelModel>
          <Input
            type="text"
            placeholder="อีเมล์"
            name="email"
            onChange={props.handleChangeAddress}
            value={props.docsAddress.email}
          />
        </div>
      )}
      <div></div>
    </>
  )
}

export default ShowCertificate
