import React, { useState, useEffect, useCallback } from "react"
import {
  TableCard,
  TableText,
  StatusText1,
  StatusText2,
  StatusText3,
  StatusText4,
  StatusText5,
  StatusText6,
  StatusText7,
  StatusText8,
  Input,
  RowContainerInput,
} from "./revokeElements"
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TablePagination,
  TableFooter,
  Box,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import authService from "../../api/axios"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const RevokeTable = () => {
  const token = localStorage.getItem("token")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [data, setDatas] = useState([])
  const [search, setSearch] = useState("")
  const [filteredResults, setFilteredResults] = useState([])

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const fetchData = useCallback(async () => {
    await authService.getDocuments(token).then((response) => {
      let dataDocs = response.data.sort((a, b) => a - b).reverse()
      setDatas(dataDocs)
      setFilteredResults(dataDocs)
    })
  }, [token])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    const searchItems = () => {
      if (search !== "") {
        const filteredData = data.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(search.toLowerCase())
        })
        setFilteredResults(filteredData)
      } else {
        setFilteredResults(data)
      }
    }
    searchItems()
  }, [data, search])
  const showDetails = (id) => {
    window.location = "/details/" + id
  }
  return (
    <>
      {data.length > 0 ? (
        <TableCard>
          <RowContainerInput>
            <TableText></TableText>
            <Input
              type="text"
              placeholder="ค้นหา..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </RowContainerInput>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">หมายเลขคำขอ</TableCell>
                  <TableCell align="center">เลขภาษี</TableCell>
                  <TableCell align="center">ชื่อผู้ขอ</TableCell>
                  <TableCell align="center">ดำเนินการ</TableCell>
                  <TableCell align="center">สำนักงาน</TableCell>
                  <TableCell align="center">วันที่ขอ</TableCell>
                  <TableCell align="center">สถานะ</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredResults
                      .filter(
                        (x) =>
                          x.objective !== "สมัครใหม่" &&
                          x.objective !== "ต่ออายุ"
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : filteredResults.filter(
                      (x) =>
                        x.objective !== "สมัครใหม่" && x.objective !== "ต่ออายุ"
                    )
                ).map((data) => (
                  <TableRow key={data._id}>
                    <TableCell component="th" scope="row" align="center">
                      {data.jobNumber}
                    </TableCell>
                    <TableCell align="center">
                      {data.taxNo || data.cardId}
                    </TableCell>
                    <TableCell align="center">
                      {data.fullNameTH || data.juristicPersonOrOrganizationTH}
                    </TableCell>
                    <TableCell align="center">{data.objective}</TableCell>
                    <TableCell align="center">
                      {data.officeLocation === "Sukhothai:Sawankhalok" ? (
                        <p>สุโขทัย : สวรรคโลก</p>
                      ) : data.officeLocation === "Sa Kaeo:Aranyaprathet" ? (
                        <p>สระแก้ว : อรัญประเทศ</p>
                      ) : data.officeLocation === "Songkhla:Mueang" ? (
                        <p>สงขลา : อำเภอเมือง</p>
                      ) : data.officeLocation === "Songkhla:Hat Yai" ? (
                        <p>สงขลา : หาดใหญ่</p>
                      ) : data.officeLocation ===
                        "Nakhon Si Thammarat:Mueang" ? (
                        <p>นครศรีธรรมราช : อำเภอเมือง</p>
                      ) : data.officeLocation === "Yala:Mueang" ? (
                        <p>ยะลา : อำเภอเมือง</p>
                      ) : data.officeLocation === "Chon Buri:Mueang" ? (
                        <p>ชลบุรี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Chon Buri:Laem Chabang" ? (
                        <p>ชลบุรี : แหลมฉบัง</p>
                      ) : data.officeLocation ===
                        "Prachuap Khiri Khan:Mueang" ? (
                        <p>ประจวบคีรีขันธ์ : อำเภอเมือง</p>
                      ) : data.officeLocation ===
                        "Prachuap Khiri Khan:Hua Hin" ? (
                        <p>ประจวบคีรีขันธ์ : หัวหิน</p>
                      ) : data.officeLocation === "Sing Buri:Mueang" ? (
                        <p>สิงห์บุรี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Rayong:Mueang" ? (
                        <p>ระยอง : อำเภอเมือง</p>
                      ) : data.officeLocation === "Rayong :Pluak Daeng" ? (
                        <p>ระยอง : ปลวกแดง</p>
                      ) : data.officeLocation === "Rayong:Map Ta Phut" ? (
                        <p>ระยอง : มาบตาพุด</p>
                      ) : data.officeLocation === "Udon Thani:Mueang" ? (
                        <p>อุดรธานี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Surat Thani:Mueang" ? (
                        <p>สุราษฎร์ธานี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Phatthalung:Mueang" ? (
                        <p>พัทลุง : อำเภอเมือง</p>
                      ) : data.officeLocation === "Suphan Buri:Mueang" ? (
                        <p>สุพรรณบุรี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Ratchaburi:Mueang" ? (
                        <p>ราชบุรี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Samut Sakhon:Mueang" ? (
                        <p>สมุทรสาคร : อำเภอเมือง</p>
                      ) : data.officeLocation ===
                        "Samut Sakhon:Krathum Baen" ? (
                        <p>สมุทรสาคร : กระทุ่มแบน</p>
                      ) : data.officeLocation === "Samut Songkhram:Mueang" ? (
                        <p>สมุทรสงคราม : อำเภอเมือง</p>
                      ) : data.officeLocation === "Mukdahan:Mueang" ? (
                        <p>มุกดาหาร : อำเภอเมือง</p>
                      ) : data.officeLocation === "Chumphon:Mueang" ? (
                        <p>ชุมพร : อำเภอเมือง</p>
                      ) : data.officeLocation === "Phuket:Mueang" ? (
                        <p>ภูเก็ต : อำเภอเมือง</p>
                      ) : data.officeLocation === "Bueng Kan:Mueangg" ? (
                        <p>บึงกาฬ : อำเภอเมือง</p>
                      ) : data.officeLocation === "Nong Khai:Mueang" ? (
                        <p>หนองคาย : อำเภอเมือง</p>
                      ) : data.officeLocation === "Chiang Mai:Mueang" ? (
                        <p>เชียงใหม่ : อำเภอเมือง</p>
                      ) : data.officeLocation === "Lamphun:Mueang" ? (
                        <p>ลำพูน : อำเภอเมือง</p>
                      ) : data.officeLocation === "Ayutthaya:Ayutthaya" ? (
                        <p>อยุธยา : พระนครศรีอยุธยา</p>
                      ) : data.officeLocation === "Chachoengsao:Mueang" ? (
                        <p>ฉะเชิงเทรา : อำเภอเมือง</p>
                      ) : data.officeLocation === "Nakhon Sawan:Mueang" ? (
                        <p>นครสวรรค์ : อำเภอเมือง</p>
                      ) : data.officeLocation === "Buri Ram:Mueang" ? (
                        <p>บุรีรัมย์ : อำเภอเมือง</p>
                      ) : data.officeLocation === "Prachin Buri:Kabin Buri" ? (
                        <p>ปราจีนบุรี : กบินทร์บุรี</p>
                      ) : data.officeLocation === "Khon Kaen:Mueang" ? (
                        <p>ขอนแก่น : อำเภอเมือง</p>
                      ) : data.officeLocation === "Nakhon Pathom:Mueang" ? (
                        <p>นครปฐม : อำเภอเมือง</p>
                      ) : data.officeLocation === "Ubon Ratchathani:Mueang" ? (
                        <p>อุบลราชธานี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Lampang:Mueang" ? (
                        <p>ลำปาง : อำเภอเมือง</p>
                      ) : data.officeLocation === "Tak:Mae Sot" ? (
                        <p>ตาก : แม่สอด</p>
                      ) : data.officeLocation === "Ranong:Mueang" ? (
                        <p>ระนอง : อำเภอเมือง</p>
                      ) : data.officeLocation === "Saraburi:Mueang" ? (
                        <p>สระบุรี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Nakhon Ratchasima:Mueang" ? (
                        <p>นครราชสีมา : อำเภอเมือง</p>
                      ) : data.officeLocation === "Kanchanaburi:Mueang" ? (
                        <p>กาญจนบุรี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Pattani:Mueang" ? (
                        <p>ปัตตานี : อำเภอเมือง</p>
                      ) : data.officeLocation === "Chiang Rai:Mueang" ? (
                        <p>เชียงราย : อำเภอเมือง</p>
                      ) : data.officeLocation === "Chanthaburi:Mueang" ? (
                        <p>จันทบุรี : อำเภอเมือง</p>
                      ) : data.officeLocation ===
                        "Pathum Thani:Khlong Luang" ? (
                        <p>ปทุมธานี : คลองหลวง</p>
                      ) : (
                        <p>กรุงเทพฯ</p>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {data.createdAt.split("T", 1)}
                    </TableCell>
                    <TableCell align="center">
                      {data.status === "ยื่นคำขอ" ? (
                        <StatusText1>{data.status}</StatusText1>
                      ) : data.status === "อนุมัติแล้ว" ? (
                        <StatusText2>{data.status}</StatusText2>
                      ) : data.status === "ถูกปฏิเสธ" ? (
                        <StatusText3>{data.status}</StatusText3>
                      ) : data.status === "อยู่ระหว่างตรวจสอบ" ? (
                        <StatusText4>{data.status}</StatusText4>
                      ) : data.status === "ส่งต้นฉบับ" ? (
                        <StatusText5>{data.status}</StatusText5>
                      ) : data.status === "รอเอกสารตอบกลับ" ? (
                        <StatusText6>{data.status}</StatusText6>
                      ) : data.status === "ออกใบรับรอง" ? (
                        <StatusText7>{data.status}</StatusText7>
                      ) : (
                        <StatusText8>{data.status}</StatusText8>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => showDetails(data._id)}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
                    count={filteredResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </TableCard>
      ) : (
        <p>ไม่มีข้อมูล</p>
      )}
    </>
  )
}

export default RevokeTable
