import React, { useState, useEffect } from "react"
import "./Styles.css"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import FormControl from "@mui/material/FormControl"
import validate from "./validate"
import authService from "../../api/axios"
import {
  BtnAdd,
  LabelModel,
  ModelContainer,
  InputModel,
  RowModel,
  BtnCreate,
  ErrorsText,
  SelectRole,
} from "./adminElements"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const officeLocation = [
  "กรุงเทพฯ : ศูนย์บริการลูกค้าแจ้งวัฒนะ",
  "กรุงเทพฯ : แจ้งวัฒนะ บริการหน่วยงานเอกชน",
  "กรุงเทพฯ : ศูนย์บริการลูกค้าบางรัก (อำคาร 16)",
  "สุโขทัย : สวรรคโลก",
  "สระแก้ว : อรัญประเทศ",
  "สงขลา : อำเภอเมือง",
  "สงขลา : หาดใหญ่",
  "นครศรีธรรมราช : อำเภอเมือง",
  "ยะลา : อำเภอเมือง",
  "ชลบุรี : อำเภอเมือง",
  "ชลบุรี : แหลมฉบัง",
  "ประจวบคีรีขันธ์ : อำเภอเมือง",
  "ประจวบคีรีขันธ์ : หัวหิน",
  "สิงห์บุรี : อำเภอเมือง",
  "ระยอง : อำเภอเมือง",
  "ระยอง : ปลวกแดง",
  "ระยอง : มาบตาพุด",
  "อุดรธานี : อำเภอเมือง",
  "สุราษฎร์ธานี : อำเภอเมือง",
  "พัทลุง : อำเภอเมือง",
  "สุพรรณบุรี : อำเภอเมือง",
  "ราชบุรี : อำเภอเมือง",
  "สมุทรสาคร : อำเภอเมือง",
  "สมุทรสาคร : กระทุ่มแบน",
  "สมุทรสงคราม : อำเภอเมือง",
  "สมุทรปราการ : อำเภอเมือง",
  "มุกดาหาร : อำเภอเมือง",
  "ชุมพร : อำเภอเมือง",
  "ภูเก็ต : อำเภอเมือง",
  "บึงกาฬ : อำเภอเมือง",
  "หนองคาย : อำเภอเมือง",
  "เชียงใหม่ : อำเภอเมือง",
  "ลำพูน : อำเภอเมือง",
  "อยุธยา : พระนครศรีอยุธยา",
  "ฉะเชิงเทรา : อำเภอเมือง",
  "นครสวรรค์ : อำเภอเมือง",
  "บุรีรัมย์ : อำเภอเมือง",
  "ปราจีนบุรี : กบินทร์บุรี",
  "ขอนแก่น : อำเภอเมือง",
  "นครปฐม : อำเภอเมือง",
  "อุบลราชธานี : อำเภอเมือง",
  "ลำปาง : อำเภอเมือง",
  "ตาก : แม่สอด",
  "ระนอง : อำเภอเมือง",
  "สระบุรี : อำเภอเมือง",
  "นครราชสีมา : อำเภอเมือง",
  "กาญจนบุรี : อำเภอเมือง",
  "ปัตตานี : อำเภอเมือง",
  "เชียงราย : อำเภอเมือง",
  "จันทบุรี : อำเภอเมือง",
  "ปทุมธานี : คลองหลวง",
]

const FormAdmin = () => {
  const [open, setOpen] = React.useState(false)
  const [role, setRole] = React.useState(
    "กรุงเทพฯ : ศูนย์บริการลูกค้าแจ้งวัฒนะ"
  )
  const handleChangeRole = (event) => {
    setRole(event.target.value)
  }
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    officeLocation: "",
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }
  const handleSubmit = (e) => {
    values.officeLocation = role
    e.preventDefault()
    setErrors(validate(values))
    setIsSubmitting(true)
    console.log(values)
  }
  useEffect(() => {
    if (role === "สุโขทัย : สวรรคโลก") {
      values.officeLocation = "Sukhothai:Sawankhalok"
    } else if (role === "สระแก้ว : อรัญประเทศ") {
      values.officeLocation = "Sa Kaeo:Aranyaprathet"
    } else if (role === "สงขลา : อำเภอเมือง") {
      values.officeLocation = "Songkhla:Mueang"
    } else if (role === "สงขลา : หาดใหญ่") {
      values.officeLocation = "Songkhla:Hat Yai"
    } else if (role === "นครศรีธรรมราช : อำเภอเมือง") {
      values.officeLocation = "Nakhon Si Thammarat:Mueang"
    } else if (role === "ยะลา : อำเภอเมือง") {
      values.officeLocation = "Yala:Mueang"
    } else if (role === "ชลบุรี : อำเภอเมือง") {
      values.officeLocation = "Chon Buri:Mueang"
    } else if (role === "ชลบุรี : แหลมฉบัง") {
      values.officeLocation = "Chon Buri:Laem Chabang"
    } else if (role === "ประจวบคีรีขันธ์ : อำเภอเมือง") {
      values.officeLocation = "Prachuap Khiri Khan:Mueang"
    } else if (role === "ประจวบคีรีขันธ์ : หัวหิน") {
      values.officeLocation = "Prachuap Khiri Khan:Hua Hin"
    } else if (role === "สิงห์บุรี : อำเภอเมือง") {
      values.officeLocation = "Sing Buri:Mueang"
    } else if (role === "ระยอง : อำเภอเมือง") {
      values.officeLocation = "Rayong:Mueang"
    } else if (role === "ระยอง : ปลวกแดง") {
      values.officeLocation = "Rayong :Pluak Daeng"
    } else if (role === "ระยอง : มาบตาพุด") {
      values.officeLocation = "Rayong:Map Ta Phut"
    } else if (role === "อุดรธานี : อำเภอเมือง") {
      values.officeLocation = "Udon Thani:Mueang"
    } else if (role === "สุราษฎร์ธานี : อำเภอเมือง") {
      values.officeLocation = "Surat Thani:Mueang"
    } else if (role === "พัทลุง : อำเภอเมือง") {
      values.officeLocation = "Phatthalung:Mueang"
    } else if (role === "สุพรรณบุรี : อำเภอเมือง") {
      values.officeLocation = "Suphan Buri:Mueang"
    } else if (role === "ราชบุรี : อำเภอเมือง") {
      values.officeLocation = "Ratchaburi:Mueang"
    } else if (role === "สมุทรสาคร : อำเภอเมือง") {
      values.officeLocation = "Samut Sakhon:Mueang"
    } else if (role === "สมุทรสาคร : กระทุ่มแบน") {
      values.officeLocation = "Samut Sakhon:Krathum Baen"
    } else if (role === "สมุทรสงคราม : อำเภอเมือง") {
      values.officeLocation = "Samut Songkhram:Mueang"
    } else if (role === "สมุทรปราการ : อำเภอเมือง") {
      values.officeLocation = "Samut Prakan:Mueang"
    } else if (role === "มุกดาหาร : อำเภอเมือง") {
      values.officeLocation = "Mukdahan:Mueang"
    } else if (role === "ชุมพร : อำเภอเมือง") {
      values.officeLocation = "Chumphon:Mueang"
    } else if (role === "ภูเก็ต : อำเภอเมือง") {
      values.officeLocation = "Phuket:Mueang"
    } else if (role === "บึงกาฬ : อำเภอเมือง") {
      values.officeLocation = "Bueng Kan:Mueang"
    } else if (role === "หนองคาย : อำเภอเมือง") {
      values.officeLocation = "Nong Khai:Mueang"
    } else if (role === "เชียงใหม่ : อำเภอเมือง") {
      values.officeLocation = "Chiang Mai:Mueang"
    } else if (role === "ลำพูน : อำเภอเมือง") {
      values.officeLocation = "Lamphun:Mueang"
    } else if (role === "อยุธยา : พระนครศรีอยุธยา") {
      values.officeLocation = "Ayutthaya:Ayutthaya"
    } else if (role === "ฉะเชิงเทรา : อำเภอเมือง") {
      values.officeLocation = "Chachoengsao:Mueang"
    } else if (role === "นครสวรรค์ : อำเภอเมือง") {
      values.officeLocation = "Nakhon Sawan:Mueang"
    } else if (role === "บุรีรัมย์ : อำเภอเมือง") {
      values.officeLocation = "Buri Ram:Mueang"
    } else if (role === "ปราจีนบุรี : กบินทร์บุรี") {
      values.officeLocation = "Prachin Buri:Kabin Buri"
    } else if (role === "ขอนแก่น : อำเภอเมือง") {
      values.officeLocation = "Khon Kaen:Mueang"
    } else if (role === "นครปฐม : อำเภอเมือง") {
      values.officeLocation = "Nakhon Pathom:Mueang"
    } else if (role === "อุบลราชธานี : อำเภอเมือง") {
      values.officeLocation = "Ubon Ratchathani:Mueang"
    } else if (role === "ลำปาง : อำเภอเมือง") {
      values.officeLocation = "Lampang:Mueang"
    } else if (role === "ตาก : แม่สอด") {
      values.officeLocation = "Tak:Mae Sot"
    } else if (role === "ระนอง : อำเภอเมือง") {
      values.officeLocation = "Ranong:Mueang"
    } else if (role === "สระบุรี : อำเภอเมือง") {
      values.officeLocation = "Saraburi:Mueang"
    } else if (role === "นครราชสีมา : อำเภอเมือง") {
      values.officeLocation = "Nakhon Ratchasima:Mueang"
    } else if (role === "กาญจนบุรี : อำเภอเมือง") {
      values.officeLocation = "Kanchanaburi:Mueang"
    } else if (role === "ปัตตานี : อำเภอเมือง") {
      values.officeLocation = "Pattani:Mueang"
    } else if (role === "เชียงราย : อำเภอเมือง") {
      values.officeLocation = "Chiang Rai:Mueang"
    } else if (role === "จันทบุรี : อำเภอเมือง") {
      values.officeLocation = "Chanthaburi:Mueang"
    } else if (role === "ปทุมธานี : คลองหลวง") {
      values.officeLocation = "Pathum Thani:Khlong Luang"
    } else {
      values.officeLocation = "HQ"
    }
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isSubmitting === true) {
        const CreateAdmin = async () => {
          try {
            const response = authService
              .createAdmin(
                values.email,
                values.password,
                values.firstName,
                values.lastName,
                values.officeLocation,
                values.phoneNumber
              )
              .then(
                () => {
                  handleClose()
                  window.location.reload()
                },
                (error) => {
                  alert(error.response.data.error)
                  console.log(error.response.data.error)
                }
              )
            console.log(response)
          } catch (err) {
            console.log(err)
          }
        }
        CreateAdmin()
      }
    } else {
      console.log("err")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])
  return (
    <>
      <BtnAdd onClick={handleOpen}>Add</BtnAdd>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Admin
          </Typography>
          <ModelContainer>
            <LabelModel>First Name</LabelModel>
            <InputModel
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
            />
            {errors.firstName && <ErrorsText>{errors.firstName}</ErrorsText>}
            <LabelModel>Last Name</LabelModel>
            <InputModel
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
            />
            {errors.lastName && <ErrorsText>{errors.lastName}</ErrorsText>}
            <LabelModel>Email</LabelModel>
            <InputModel
              autoComplete="off"
              type="text"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && <ErrorsText>{errors.email}</ErrorsText>}
            <LabelModel>Password</LabelModel>
            <InputModel
              autoComplete="off"
              type="password"
              name="password"
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && <ErrorsText>{errors.password}</ErrorsText>}
            <LabelModel>Phone Number</LabelModel>
            <InputModel
              type="text"
              name="phoneNumber"
              onChange={handleChange}
              value={values.phoneNumber}
            />
            {errors.phoneNumber && (
              <ErrorsText>{errors.phoneNumber}</ErrorsText>
            )}
            <LabelModel>Office Location</LabelModel>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <SelectRole
                  labelId="simple-select-label"
                  id="simple-select"
                  value={role}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChangeRole}
                  fullWidth
                >
                  {officeLocation.map((officeLocation) => (
                    <option key={officeLocation} value={officeLocation}>
                      {officeLocation}
                    </option>
                  ))}
                </SelectRole>
              </FormControl>
            </Box>
            <RowModel>
              <BtnCreate onClick={handleClose}>Cancel</BtnCreate>
              <BtnCreate onClick={handleSubmit}>Create</BtnCreate>
            </RowModel>
          </ModelContainer>
        </Box>
      </Modal>
    </>
  )
}

export default FormAdmin
