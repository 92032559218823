import React, { useState, useEffect, useCallback } from "react"
import { BtnEdit } from "./adminElements"
import "./Styles.css"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import authService from "../../api/axios"
import {
  LabelModel,
  ModelContainer,
  InputModel,
  RowModel,
  BtnCreate,
} from "./adminElements"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const officeLocation = [
  "HQ",
  "Sukhothai:Sawankhalok",
  "Sa Kaeo:Aranyaprathet",
  "Songkhla:Mueang",
  "Songkhla:Hat Yai",
  "Nakhon Si Thammarat:Mueang",
  "Yala:Mueang",
  "Chon Buri:Mueang",
  "Chon Buri:Laem Chabang",
  "Prachuap Khiri Khan:Mueang",
  "Prachuap Khiri Khan:Hua Hin",
  "Sing Buri:Mueang",
  "Rayong:Mueang",
  "Rayong :Pluak Daeng",
  "Rayong:Map Ta Phut",
  "Udon Thani:Mueang",
  "Surat Thani:Mueang",
  "Phatthalung:Mueang",
  "Suphan Buri:Mueang",
  "Ratchaburi:Mueang",
  "Samut Sakhon:Mueang",
  "Samut Sakhon:Krathum Baen",
  "Samut Songkhram:Mueang",
  "Samut Prakan:Mueang",
  "Mukdahan:Mueang",
  "Chumphon:Mueang",
  "Phuket:Mueang",
  "Bueng Kan:Mueang",
  "Nong Khai:Mueang",
  "Chiang Mai:Mueang",
  "Lamphun:Mueang",
  "Ayutthaya:Ayutthaya",
  "Chachoengsao:Mueang",
  "Nakhon Sawan:Mueang",
  "Buri Ram:Mueang",
  "Prachin Buri:Kabin Buri",
  "Khon Kaen:Mueang",
  "Nakhon Pathom:Mueang",
  "Ubon Ratchathani:Mueang",
  "Lampang:Mueang",
  "Tak:Mae Sot",
  "Ranong:Mueang",
  "Saraburi:Mueang",
  "Nakhon Ratchasima:Mueang",
  "Kanchanaburi:Mueang",
  "Pattani:Mueang",
  "Chiang Rai:Mueang",
  "Chanthaburi:Mueang",
  "Pathum Thani:Khlong Luang",
]

const FormEdit = (props) => {
  const token = localStorage.getItem("token")
  const [open, setOpen] = React.useState(false)
  const [role, setRole] = React.useState("")
  const [id] = useState(props.adminId)
  const handleChangeRole = (event) => {
    setRole(event.target.value)
  }
  const [, setIsSubmitting] = useState(false)
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    officeLocation: "",
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }
  const handleSubmit = async (e) => {
    values.officeLocation = role
    e.preventDefault()
    setIsSubmitting(true)
    await authService
      .updateAdmin(
        token,
        id,
        values.firstName,
        values.lastName,
        values.phoneNumber
      )
      .then(
        () => {
          handleClose()
          window.location.reload()
        },
        (error) => {
          console.log(error)
        }
      )
  }
  const fetchData = useCallback(async () => {
    await authService.getAdminInfo(token, props.adminId).then((response) => {
      let newData = response.data
      setValues({
        firstName: newData.firstName,
        lastName: newData.lastName,
        email: newData.email,
        phoneNumber: newData.phone,
        officeLocation: newData.officeLocation,
      })
      setRole(newData.officeLocation)
    })
  }, [props.adminId, token])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <>
      <BtnEdit onClick={handleOpen}>แก้ไข</BtnEdit>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Admin
          </Typography>
          <ModelContainer>
            <LabelModel>First Name</LabelModel>
            <InputModel
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
            />
            <LabelModel>Last Name</LabelModel>
            <InputModel
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
            />
            <LabelModel>Email</LabelModel>
            <InputModel
              disabled
              autoComplete="off"
              type="text"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            <LabelModel>Phone Number</LabelModel>
            <InputModel
              type="text"
              name="phoneNumber"
              onChange={handleChange}
              value={values.phoneNumber}
            />
            <LabelModel>Office Location</LabelModel>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select
                  disabled
                  labelId="simple-select-label"
                  id="simple-select"
                  value={role}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChangeRole}
                  fullWidth
                >
                  {officeLocation.map((officeLocation) => (
                    <MenuItem
                      key={officeLocation}
                      value={officeLocation}
                      sx={{
                        fontFamily: "Kanit",
                      }}
                    >
                      {officeLocation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <RowModel>
              <BtnCreate onClick={handleClose}>Cancel</BtnCreate>
              <BtnCreate onClick={handleSubmit}>Save</BtnCreate>
            </RowModel>
          </ModelContainer>
        </Box>
      </Modal>
    </>
  )
}

export default FormEdit
