import React from "react"
import { HeroContainer, WelcomeText, RowContainer } from "./revokeElements"
import RevokeTable from "./RevokeTable"

const Revoke = () => {
  return (
    <>
      <HeroContainer>
        <WelcomeText>ขอเพิกถอน</WelcomeText>
        <RowContainer>
          <RevokeTable></RevokeTable>
        </RowContainer>
      </HeroContainer>
    </>
  )
}

export default Revoke
