import React from "react"
import { HeroContainer, WelcomeText, RowContainer } from "./submitElements"
import SubmitTable from "./SubmitTable"

const Submit = () => {
  return (
    <>
      <HeroContainer>
        <WelcomeText>คำขอใหม่</WelcomeText>
        <RowContainer>
          <SubmitTable></SubmitTable>
        </RowContainer>
      </HeroContainer>
    </>
  )
}

export default Submit
