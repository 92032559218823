import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const HeroContainer = styled.div`
  background: #f8f9fa;
  display: flex;
  margin-top: 80px;
  margin-left: 250px;
  min-height: 100vh;
  flex-direction: column;
`
export const WelcomeText = styled.h2`
  margin-top: 2rem;
  margin-left: 10%;
  font-weight: bold;
  color: #ffd100;
`
export const RowContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 10%;
  margin-right: 5%;
  justify-content: space-between;
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const HeadText = styled.div`
  background: #fff;
  border-radius: 30px;
  color: #545859;
  width: 80%;
  height: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #363a3d;
  text-align: center;
`
export const TitleText = styled.div`
  margin-top: 1rem;
  color: #545859;
  width: 180px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`
export const TableCard = styled.div`
  width: 100%;
  min-height: 40vh;
  background: #fff;
  border-radius: 8px;
  color: #000;
  margin-bottom: 4rem;
  padding: 2rem;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
`
export const StatusText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ffd100;
`
export const TableText = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-between;
`
export const LinkDetail = styled(LinkR)`
  font-weight: 300;
`
export const BtnAdd = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  width: 100px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const BtnCreate = styled.button`
  margin-left: 1rem;
  border-radius: 8px;
  text-align: center;
  background: #ffd100;
  white-space: nowrap;
  min-width: 100px;
  height: 40px;
  padding: 8px 8px;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    border: 1px solid #ffd100;
    color: #ffd100;
  }
`
export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 30%;
  height: 40px;
  padding: 1rem;
  border: 1px solid #ffd100;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const SelectRole = styled.select`
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  padding-left: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
`
export const ModelContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const InputModel = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  padding: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    border: 1px solid #ffd100;
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
`
export const LabelModel = styled.label`
  color: #3c354e;
  font-size: 15px;
`
export const RowModel = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
export const ErrorsText = styled.p`
  color: red;
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
`
export const BtnEdit = styled.button`
  text-align: center;
  white-space: nowrap;
  background: none;
  color: #ffd100;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
`
